import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import {
  GetFirmwareList,
  GetFirmwareUpdate,
  DeleteFirmware,
} from "pages/gateway-management/index";
import { connect, useSelector } from "react-redux";
import PageDataLoader from "partials/loader";
import UploadFirmwareModal from "./uploadFirmwareModal";

const ManageFirmware = ({
  GetFirmwareList,
  GetFirmwareUpdate,
  DeleteFirmware,
}) => {
  const [pageLoader, setPageLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [file, setFile] = useState();
  const [updateType, setUpdateType] = useState("Major");
  const [version, setVersion] = useState();
  const [description, setDescription] = useState();

  let firmwareList = useSelector((state) => state.gateway.firmwareList);
  const tableSelect = selectedRows.length > 0;

  useEffect(() => {
    setPageLoader(true);
    GetFirmwareList({}, () => setPageLoader(false));
  }, []);

  const columns = [
    {
      title: "File Name",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "Update Type",
      dataIndex: "updatetype",
      align: "center",
    },
    {
      title: "Version",
      dataIndex: "version",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "TImestamp",
      dataIndex: "timestamp",
      align: "center",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const deleteFirmware = () => {
    setPageLoader(true);
    DeleteFirmware(
      {
        id: selectedRows?.[0]?.id,
      },
      () => {
        GetFirmwareList({}, () => setPageLoader(false));
      }
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("updateType", updateType);
    formData.append("version", version);
    formData.append("description", description);
    setPageLoader(true);
    GetFirmwareUpdate(formData, handleRefresh);
    setDescription();
    setFile();
    setVersion();
    setUpdateType();
    setSelectedRows([]);
  };

  const handleRefresh = () => {
    setPageLoader(true);
    GetFirmwareList({}, () => setPageLoader(false));
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "white",
          padding: "15px",
        }}
      >
        <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
          Manage Firmware
        </div>

        <Row>
          <Col
            xl={{ span: 2, offset: 16 }}
            lg={{ span: 1, offset: 17 }}
            md={{ span: 1, offset: 10 }}
            sm={{ span: 1, offset: 10 }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              title="Refresh"
              icon={<ReloadOutlined />}
              onClick={handleRefresh}
            ></Button>
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              onClick={showModal}
            >
              Upload
            </Button>
            <Modal
              title="Upload Firmware"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[]}
            >
              <UploadFirmwareModal
                setPageLoader={setPageLoader}
                setIsModalOpen={setIsModalOpen}
                handleRefresh={handleRefresh}
              />
            </Modal>
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              onClick={deleteFirmware}
              disabled={!tableSelect}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: "0.5rem" }}>
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey={(record) => record.timestamp}
              columns={columns}
              dataSource={firmwareList}
            />
          </Col>
        </Row>
        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetFirmwareList: (obj, callback) => dispatch(GetFirmwareList(obj, callback)),
  GetFirmwareUpdate: (obj, callback) =>
    dispatch(GetFirmwareUpdate(obj, callback)),
  DeleteFirmware: (obj, callback) => dispatch(DeleteFirmware(obj, callback)),
});
export default connect(null, mapDispatchToProps)(ManageFirmware);
// export default ManageFirmware;
