import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import boschIcon from "assets/bosch.png";
import assetmanagementIcon from "assets/assetmanagementIcon.svg";
import desktopIcon from "assets/desktopIcon.svg";
import mappingsignalIcon from "assets/mappingsignalIcon.svg";
import customerIcon from "assets/customerIcon.svg";
import visualizationIcon from "assets/visualizationIcon.svg";
import usersIcom from "assets/users.svg";
import fotaIcon from "assets/fotaIcon.svg";
import abbIcon from "assets/abbIcon.svg";
import { Link } from "react-router-dom";
import "./menustyle.css";

const SideMenu = () => {
  const [selectedKey, setSelectedKey] = useState(false);
  const [menu, setMenu] = useState("dashboard");

  const locations = [
    {
      path: "/home",
      key: "1",
    },
    {
      path: "/gateways",
      key: "2",
    },
    {
      path: "/firmwares",
      key: "3",
    },
    {
      path: "/software-packages",
      key: "4",
    },
    {
      path: "/customers",
      key: "5",
    },
    {
      path: "/robot-dashboard",
      key: "6",
    },
    {
      path: "/user-management",
      key: "7",
    },
  ];
  useEffect(() => {
    let i = window.location.pathname.indexOf("/", 1);
    let pathCheck;
    if (i !== -1) {
      pathCheck = window.location.pathname.slice(0, i);
    }
    locations.map((item) => {
      if (window.location.pathname === item.path || item.path === pathCheck)
        setSelectedKey(item.key);
    });
  }, [menu]);

  return (
    <div>
      <div
        style={{
          textAlign: "center",
          marginTop: "1rem",
        }}
      >
        <img src={boschIcon} alt="" width="120" height="30" />
      </div>
     
      <Menu
        defaultSelectedKeys={["1"]}
        selectedKeys={selectedKey}
        mode="inline"
        style={{ fontSize: "0.8rem" }}
        onClick={(e) => setMenu(e.key)}
      >
        <Menu.Item
          key="1"
          icon={<img src={desktopIcon} alt=" " className="menuIconStyle" />}
        >
          <Link to="/home">Dashboard </Link>
        </Menu.Item>

        <Menu.Item
          key="2"
          icon={
            <img src={assetmanagementIcon} alt=" " className="menuIconStyle" />
          }
        >
          <Link to="/gateways">Gateways</Link>
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<img src={fotaIcon} alt=" " className="menuIconStyle" />}
        >
          <Link to="/firmwares">Firmwares</Link>
        </Menu.Item>
         <Menu.Item
          key="4"
          icon={
            <img src={mappingsignalIcon} alt=" " className="menuIconStyle" />
          }
        >
          <Link to="/software-packages">Software Packages</Link>
        </Menu.Item> 
        <Menu.Item
          key="5"
          icon={<img src={customerIcon} alt=" " className="menuIconStyle" />}
        >
          <Link to="/customers">Customers</Link>
        </Menu.Item>

        {/* <Menu.Item
          key="6"
          icon={
            <img src={visualizationIcon} alt=" " className="menuIconStyle" />
          }
        >
          <Link to="/robot-dashboard"> Dashboard Builder</Link>
        </Menu.Item> */}
        <Menu.Item
          key="7"
          icon={
            <img src={usersIcom} alt=" " className="menuIconStyle" />
          }
        >
          <Link to="/user-management"> User Management</Link>
        </Menu.Item>
      </Menu>
      <div
        style={{
          textAlign: "center",
          marginTop: "50vh",
        }}
      >
        {/* <img src={boschIcon} alt="" width="120" height="30" /> */}
      </div>
    </div>
  );
};
export default SideMenu;
