import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Card,
  notification,
  Spin,
} from "antd";
import backgroundImage from "assets/bg.jpg";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ValidateTokenFromNewUser, SetPasswordForNewUser } from "./index";
const { Title } = Typography;
const ValidateToken = ({ValidateTokenFromNewUser, SetPasswordForNewUser}) => {
  const validationTokenFromLink = useParams();
  const navigate = useNavigate();
  const [validationToken, setValidationToken] = useState("");
  const [pageLoader, setPageLoader] = useState(false);
  useEffect(() => {
    setPageLoader(true)
    if (validationTokenFromLink) {
      setValidationToken(validationTokenFromLink);
    }
    ValidateTokenFromNewUser({validationTokenFromLink}, (res)=>{
      if(res?.status === 200){
        setPageLoader(false)
      }
    }
  )
  }, [validationTokenFromLink]);

  
  const onFinish = (values) => {
    setPageLoader(true);
    const loginDetails = {
      password: values?.password,
      token :validationToken?.id
    };
    SetPasswordForNewUser(loginDetails,(res)=>{
      if (res) {
        
        setPageLoader(false);
        notification.success({ description: "Set password successfully " });
        navigate("/");
      }else{
        setPageLoader(false);
        notification.error({ description: "Incorrect Username & Password" });
      }
    })
  };
  return (
    <div
      style={{
        overflowY: "hidden",
        height: "100vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <Spin style={{fontSize:"40px"}} size="large" spinning={pageLoader} delay={500}  tip="Validating information ...." >
      <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Col
          style={{
            marginTop: "15%",
          }}
        >
          <Card
            bordered={false}
            style={{
              justifyContent: "center",
              width: "50vh",
              border: "2px solid black",
            }}
          >
            <div>
              <Title style={{ marginBottom: "30px" }}>Set Password</Title>
              <Form
                onFinish={onFinish}
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please enter a password." },
                    {
                      min: 8,
                      message: "Password should be at least 8 characters long.",
                    },
                    {
                      pattern:
                        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])/,
                      message:
                        "Password should contain uppercase and lowercase letters, numbers, and special characters.",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password.",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Passwords do not match.");
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
      </Spin>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
    ValidateTokenFromNewUser: (obj,callback) => dispatch(ValidateTokenFromNewUser(obj, callback)),
    SetPasswordForNewUser: (obj,callback) => dispatch(SetPasswordForNewUser(obj, callback)),
  });
  export default connect(null, mapDispatchToProps)(ValidateToken);

