import React, { useState } from "react";
import Header from "partials/header";
import PageDataLoader from "partials/loader";
import {
  Card,
  Row,
  Col,
  Breadcrumb,
  Button,
  Modal,
  Input,
  Form,
  InputNumber,
  notification,
  Popconfirm,
} from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import "./Styles.css";
import { connect, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DeleteCustomer,
  GetCustomerList,
  AddUser,
  EditUser,
} from "pages/customer-site/index";

let path = "";
const CustomersSite = ({
  GetCustomerList,
  DeleteCustomer,
  AddUser,
  EditUser,
}) => {
  const [form] = Form.useForm();
  const customers = useSelector((state) => state.customerSite.customerData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [apiRecall, setApiRecall] = useState(false);
  const [editData, setSetEditData] = useState();
  const [editDataAccess, setEditDataAccess] = useState(false);
  const [pathBreadData, setPathBreadData] = useState(["customer"]);
  const [initialPathName, setInitialPathName] = useState("customer");

  useEffect(() => {
    setPageLoader(true);
    GetCustomerList({ initialPathName }, () => setPageLoader(false));
  }, [apiRecall]);

  // let pathData = customers?.[0]?.path?.split(".");
  const handleBreadComb = (initialPathName) => {
    setInitialPathName(initialPathName);
    GetCustomerList({ initialPathName });
    const updateBreadcomb = pathBreadData;
    const indexToKeep = updateBreadcomb.indexOf(initialPathName);
    const newupdateBreadcomb = updateBreadcomb.slice(0, indexToKeep + 1);
    setPathBreadData(newupdateBreadcomb);
  };

  const onFinish = (values) => {
    if (editData && editDataAccess == true) {
      const formdata = form.getFieldsValue();
      const editHierarchyData = {
        name: formdata?.name,
        hierarchy_type: formdata?.hierarchy_type,
        customer: pathBreadData?.[0],
        site:
          pathBreadData?.length === 1
            ? values?.name
            : pathBreadData?.[1]
            ? pathBreadData?.[1]
            : "",
        container:
          pathBreadData?.length === 2
            ? values?.name
            : pathBreadData?.[2]
            ? pathBreadData?.[2]
            : "",
        bank:
          pathBreadData?.length === 3
            ? values?.name
            : pathBreadData?.[3]
            ? pathBreadData?.[3]
            : "",
        subbank:
          pathBreadData?.length === 4
            ? values?.name
            : pathBreadData?.[4]
            ? pathBreadData?.[4]
            : "",
        string:
          pathBreadData?.length === 5
            ? values?.name
            : pathBreadData?.[5]
            ? pathBreadData?.[5]
            : "",
        module:
          pathBreadData?.length === 6
            ? values?.name
            : pathBreadData?.[6]
            ? pathBreadData?.[6]
            : "",
        cell:
          pathBreadData?.length === 7
            ? values?.name
            : pathBreadData?.[7]
            ? pathBreadData?.[7]
            : "",
      };
      let finalEditData = { editHierarchyData, editData };
      EditUser(finalEditData, (res) => {
        setApiRecall(!apiRecall);
        setEditDataAccess(false)
      });
    } else {
      const userAddData = {
        guid: values?.guid,
        name: values?.name,
        hierarchy_type: values?.hierarchy_type,
        customer: pathBreadData?.[0],
        site:
          pathBreadData?.length === 1
            ? values?.name
            : pathBreadData?.[1]
            ? pathBreadData?.[1]
            : "",
        container:
          pathBreadData?.length === 2
            ? values?.name
            : pathBreadData?.[2]
            ? pathBreadData?.[2]
            : "",
        bank:
          pathBreadData?.length === 3
            ? values?.name
            : pathBreadData?.[3]
            ? pathBreadData?.[3]
            : "",
        subbank:
          pathBreadData?.length === 4
            ? values?.name
            : pathBreadData?.[4]
            ? pathBreadData?.[4]
            : "",
        string:
          pathBreadData?.length === 5
            ? values?.name
            : pathBreadData?.[5]
            ? pathBreadData?.[5]
            : "",
        module:
          pathBreadData?.length === 6
            ? values?.name
            : pathBreadData?.[6]
            ? pathBreadData?.[6]
            : "",
        cell:
          pathBreadData?.length === 7
            ? values?.name
            : pathBreadData?.[7]
            ? pathBreadData?.[7]
            : "",
      };
      AddUser(userAddData, (res) => {
        setApiRecall(!apiRecall);
      });
    }
  };
  const handleCardClick = (event, item) => {
    setPageLoader(true);
    event.stopPropagation();
    setInitialPathName(item.name);
    GetCustomerList({ initialPathName: item.name }, () => setPageLoader(false));
  };

  const handleButtonClick = (editEvent, editItem) => {
    editEvent.stopPropagation();
    if (editItem) {
      form.setFieldsValue({
        name: editItem?.name,
        hierarchy_type: editItem?.hierarchy_type,
        guid: editItem?.guid,
      });
    }

    setIsModalOpen(true);
   
  };
  const handleDeleteClick = (deleteEvent, deleteItem) => {
    deleteEvent.stopPropagation();
    DeleteCustomer(deleteItem, (res) => {
      notification.success({ description: "Successfully Deleted" });
      setApiRecall(!apiRecall);
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        <Header pagename={"Tenant/Organization Details"} />
        <div style={{ padding: "5px" }}>
          <Breadcrumb>
            {pathBreadData?.map((currentValue, index) => {
              return (
                <Breadcrumb.Item key={index}>
                  <a
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    onClick={() => handleBreadComb(currentValue)}
                  >
                    {currentValue}
                  </a>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </div>

        <div style={{ textAlign: "right", padding: "20px 20px 0px 0px" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#6B9E9A",
              color: "black",
            }}
            onClick={() => {
              form.resetFields();
              setIsModalOpen(true);
            }}
          >
            Add Tenant
          </Button>

          <Modal
            title="Add Tenant"
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => {
              setIsModalOpen(false);
              form.resetFields();
            }}
            footer={null}
          >
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Tenant/Organization Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Tenant/Organization Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Type"
                name="hierarchy_type"
                rules={[
                  {
                    required: true,
                    message: "Please input Type !",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Level"
                name="guid"
                rules={[
                  {
                    required: true,
                    message: "Please input Level !",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                >
                  Submit
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsModalOpen(false)}
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                    marginLeft: "20px",
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>

        <div style={{ padding: "10px" }}>
          <PageDataLoader visible={pageLoader} />

          <Row gutter={[1, 24]}>
            {customers?.length === 0 ? (
              <div>No Data</div>
            ) : (
              <>
                {" "}
                {customers?.map((item, index) => {
                  return (
                    <Col
                      xxl={{ span: 6 }}
                      xl={{ span: 8 }}
                      lg={{ span: 12 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Card
                        className="CustomerCardStyle"
                        onClick={(event) => {
                          setPathBreadData((prev) => [...prev, item.name]);
                          handleCardClick(event, item);
                        }}
                      >
                        <Row className="spectrum-background">
                          <span className="spectrum-background-text">
                            {item?.name}
                          </span>
                          {/* <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        okText="Yes"
                        cancelText="No"
                      > */}
                          <DeleteOutlined
                            className="spectrum-background-icon"
                            onClick={(event) =>
                              handleDeleteClick(event, item?.guid)
                            }
                          />
                          {/* </Popconfirm> */}
                        </Row>
                        <Row>
                          <Col span={8} className="elevatorCardsStyle1">
                            <div className="CardHeadr">Guid</div>
                            <div className="CardAnswere1">{item?.guid}</div>
                          </Col>
                          <Col span={8} className="elevatorCardsStyle2">
                            <div className="CardHeadr">Date</div>
                            <div className="CardAnswere">
                              {/* {item?.DATE} */}
                              29 May 2023
                            </div>
                          </Col>
                          <Col span={8} className="elevatorCardsStyle3">
                            <div className="CardHeadr">Alerts</div>
                            <div className="CardAnswere2">
                              {/* {item?.Alerts} */}
                              45
                            </div>
                          </Col>
                        </Row>
                        <Row className="CardHeadr"> Information : </Row>
                        <Row className="CardAnswere1">
                          {/* {item?.body} */}
                          Information of the card will be intigrated soon.
                        </Row>
                        <Button
                          shape="circle"
                          icon={<EditOutlined />}
                          className="editButton"
                          onClick={(event) => {
                            handleButtonClick(event, item);
                            setSetEditData(item);
                            setEditDataAccess(true)
                          }}
                        />
                        <Button
                          shape="circle"
                          icon={<EyeOutlined />}
                          className="previewButton"
                          // onClick={handleButtonClick}
                        />
                      </Card>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetCustomerList: (obj, callback) => dispatch(GetCustomerList(obj, callback)),

  DeleteCustomer: (obj, callback) => dispatch(DeleteCustomer(obj, callback)),

  AddUser: (obj, callback) => dispatch(AddUser(obj, callback)),

  EditUser: (obj, callback) => dispatch(EditUser(obj, callback)),
});

export default connect(null, mapDispatchToProps)(CustomersSite);

// export default CustomersSite;
