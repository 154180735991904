import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

function MapContainer({ dataSource }) {
  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    setActiveMarker(marker);
  };

  let center = {
    lat: 12.9716,
    lng: 77.5946,
  };
  if (dataSource?.length > 0) {
    let data = dataSource?.[0];
    center = {
      lat: parseInt(data?.geolocation?.latitude),
      lng: parseInt(data?.geolocation?.longitude),
    };
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAQ0-eJOoHhRcNji6BoPo6PapZ-tcNeRdE",
  });

  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            minWidth: "100%",
            height: "61vh",
            minHeight: "95%",
          }}
          center={center}
          zoom={3}
        >
          {dataSource?.map((item, index) => {
            return (
              <Marker
                position={{
                  lat: parseFloat(item?.geolocation?.latitude),
                  lng: parseFloat(item?.geolocation?.longitude),
                }}
                onClick={() => handleActiveMarker(index)}
                onMouseOver={{}}
                key={index}
              >
                {activeMarker === index ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div key={index}>
                      <b>{item?.devicename}</b>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })}
        </GoogleMap>
      )}
    </div>
  );
}
export default React.memo(MapContainer);
