import React, { StrictMode, useEffect, useState } from "react";
import Header from "partials/header";
import { Col, Row, Select, Table, Button } from "antd";
import { EyeOutlined, DashboardOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import MapContainer from "./mapContainer";
import { gatewayActions } from "pages/gateway-management/store";
import { connect, useDispatch, useSelector } from "react-redux";
import { GetDeviceList } from "pages/gateway-management/index";
import PageDataLoader from "partials/loader";
const { Option } = Select;

const Dashboard = ({ GetDeviceList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageLoader, setPageLoader] = useState(false);
  const [regionSelected, SetRegionSelected] = useState("India");
  const deviceData = useSelector((state) => state.gateway.deviceData);

  useEffect(() => {
    setPageLoader(true);
    GetDeviceList({}, () => setPageLoader(false));
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "devicename",
      align: "center",
      width: 350,
      render: (text, record, index) => (
        <>
          <a
            href={`/gateways/edit-gateway?deviceId=${record?.deviceId}`}
            onClick={(e) => {
              e.stopPropagation(); // Prevent the event from bubbling up
            }}
          >
            {text}
          </a>
        </>
      ),
    },
    // {
    //   title: "Model No",
    //   dataIndex: "modelno",
    //   align: "center",
    // },
    {
      title: "Status",
      dataIndex: "connectionState",
      align: "center",
      render: (text) => (
        <>
          {text === "Connected" ? (
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: "#32CD32",
              }}
            ></div>
          ) : (
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: "red",
              }}
            ></div>
          )}
        </>
      ),
    },
    // {
    //   title: "Details",
    //   dataIndex: "details",
    //   align: "center",
    //   render: (text, record, index) => (
    //     <>
    //       <Button
    //         style={{ border: "none", marginRight: "1rem" }}
    //         onClick={(e) => {
    //           localStorage.setItem("deviceId", record?.deviceId);
    //           dispatch(
    //             gatewayActions.DeviceDetails({
    //               deviceDetails: {
    //                 deviceId: record?.deviceId,
    //                 otherDetails: [],
    //               },
    //             })
    //           );
    //           navigate("/gateways/edit-gateway");
    //         }}
    //         title="Click here to View Device"
    //       >
    //         <EyeOutlined />
    //       </Button>
    //     </>
    //   ),
    // },
    // {
    //   title: "Live Dashboard",
    //   dataIndex: "liveDashboard",
    //   align: "center",
    //   render: (text, record, index) => (
    //     <>
    //       <Button
    //         style={{ border: "none", backgound: "none" }}
    //         onClick={(e) => handleButton(e, record)}
    //       >
    //         <DashboardOutlined />
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  const handleButton = (e, record) => {
    const url = record?.url;
    window.open(url, "_blank").focus();
  };

  let result = deviceData?.map((a) => a.region);
  let uniqueRegions = [...new Set(result)];

  const groupByRegion = (array, property) => {
    return array?.reduce((item, cur) => {
      item[cur[property]] = [...(item[cur[property]] || []), cur];
      return item;
    }, {});
  };

  let regionwiseData = groupByRegion(deviceData, "region")?.[regionSelected];
  let AutomotiveDevices = groupByRegion(regionwiseData, "productionline")?.[
    "Automotive"
  ];
  let ElectricalDevices = groupByRegion(regionwiseData, "productionline")?.[
    "Electrical & Electonic"
  ];

  const handleRegion = (value) => {
    SetRegionSelected(value);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Header pagename={"Home"} />
        <div
          style={{
            padding: "10px",
            height: "90vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Row
            style={{
              padding: "10px",
            }}
            gutter={[24, 24]}
          >
            <Col
              xl={{ span: 3 }}
              lg={{ span: 3 }}
              md={{ span: 8 }}
              sm={{ span: 10 }}
              xs={{ span: 24 }}
              style={{ margin: "0px 5px 0px 5px" }}
            >
              <Select
                style={{
                  width: "100%",
                }}
                allowClear
                showSearch
                placeholder="Countries"
                value={regionSelected}
                onChange={(value) => {
                  handleRegion(value);
                }}
              >
                {uniqueRegions?.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col
              xl={{ span: 3 }}
              lg={{ span: 3 }}
              md={{ span: 8 }}
              sm={{ span: 10 }}
              xs={{ span: 24 }}
              style={{ margin: "0px 5px 0px 5px" }}
            ></Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col md={12} sm={24}>
              <h1>Group 1</h1>
              <Table
                dataSource={AutomotiveDevices}
                columns={columns}
                pagination={false}
              />

              <>
                <h1>Group 2</h1>
                <Table
                  dataSource={ElectricalDevices}
                  columns={columns}
                  pagination={false}
                />
              </>
            </Col>
            <Col
              md={{ span: 10, offset: 2 }}
              style={{ height: "80vh" }}
              sm={24}
            >
              <MapContainer dataSource={regionwiseData} />
            </Col>
          </Row>
        </div>
        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetDeviceList: (obj, callback) => dispatch(GetDeviceList(obj, callback)),
});
export default connect(null, mapDispatchToProps)(Dashboard);
// export default Dashboard;
