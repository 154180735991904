import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Modal, notification } from "antd";
import { connect, useSelector, useDispatch } from "react-redux";
import ScheduleFirmwareModal from "./scheduleFirmwareModal";
import {
  GetDeviceList,
  GetFirmwareUpdateList,
  SetScheduleFirmware,
} from "pages/gateway-management/index";
import PageDataLoader from "partials/loader";
import FirmwareJobDetails from "./firmwareJobDetails";

const ScheduleFirmware = ({ GetFirmwareUpdateList, GetDeviceList }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [jobDetails, setJobDetails] = useState({ state: false, id: "" });
  const [params, setParams] = useState({});
  let firmwareUpdateList = useSelector(
    (state) => state.gateway.firmwareUpdateList
  );

  useEffect(() => {
    if (Object.keys(params).length) {
      setPageLoader(true);
      dispatch(
        SetScheduleFirmware(params, (res) => {
          GetFirmwareUpdateList({}, () => setPageLoader(false));
          notification.open({
            message: "Notification",
            description: res.message,
            duration: [3],
          });
        })
      );
    }
  }, [params]);
  const columns = [
    {
      title: "Job Id",
      dataIndex: "jobid",
      align: "center",
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={(e) => {
              setJobDetails({
                id: text,
                state: true,
                devices: record?.devicelist,
                filename: record?.filename,
              });
            }}
          >
            {text}
          </Button>
        );
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "Device List",
      dataIndex: "devicelist",
      align: "center",
      render: (text, record) => {
        return <>{text.toString()}</>;
      },
    },
    {
      title: "Update Type",
      dataIndex: "updatetype",
      align: "center",
    },
    {
      title: "Version",
      dataIndex: "version",
      align: "center",
    },

    {
      title: "Timestamp",
      dataIndex: "timestamp",
      align: "center",
    },
  ];

  useEffect(() => {
    setPageLoader(true);
    GetFirmwareUpdateList({}, () => {
      GetDeviceList({}, () => setPageLoader(false));
    });
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "white",
          padding: "15px",
        }}
      >
        <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
          Schedule Firmware Updates
        </div>

        {jobDetails.state ? (
          <FirmwareJobDetails
            setJobDetails={setJobDetails}
            jobDetails={jobDetails}
          />
        ) : (
          <>
            <Row>
              <Col
                xl={{ span: 3, offset: 21 }}
                lg={{ span: 3, offset: 21 }}
                md={{ span: 6, offset: 18 }}
                sm={{ span: 6, offset: 18 }}
              >
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                  onClick={showModal}
                >
                  Schedule
                </Button>
                <Modal
                  title="Schedule a Job"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={[]}
                >
                  <ScheduleFirmwareModal
                    setIsModalOpen={setIsModalOpen}
                    called="firmware"
                    setParams={setParams}
                  />
                </Modal>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: "0.5rem" }}>
                <Table columns={columns} dataSource={firmwareUpdateList} />
              </Col>
            </Row>
          </>
        )}
        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetFirmwareUpdateList: (obj, callback) =>
    dispatch(GetFirmwareUpdateList(obj, callback)),
  GetDeviceList: (obj, callback) => dispatch(GetDeviceList(obj, callback)),
});
export default connect(null, mapDispatchToProps)(ScheduleFirmware);

// export default ScheduleFirmware;
