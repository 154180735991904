import { Row, Button, Divider, Col, Table } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CancelJobDetails,
  GetJobDetails,
  GetDeviceVersions,
} from "pages/gateway-management/index";
import PageDataLoader from "partials/loader";

const SoftwareJobDetails = ({ setJobDetails, jobDetails }) => {
  const dispatch = useDispatch();
  const [pageLoader, setPageLoader] = useState(false);
  const devices = jobDetails?.devices;
  const filename = jobDetails?.filename;

  const jobData = useSelector((state) => state.gateway.firmwareJobDetails);
  const deviceVersions = useSelector((state) => state.gateway.deviceVersions);

  const columns = [
    {
      title: "Device Id",
      dataIndex: "deviceId",
      align: "center",
    },
    {
      title: "File Name",
      dataIndex: "filename",
      align: "center",
      render: (text) => {
        return <>{filename}</>;
      },
    },
    {
      title: "Firmware Version",
      dataIndex: "firmwareversion",
      align: "center",
      render: (text, record) => {
        return <>{record?.properties?.reported?.firmwareversion}</>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => {
        return <>{jobData?.status}</>;
      },
    },
  ];

  useEffect(() => {
    setPageLoader(true);
    dispatch(GetJobDetails(jobDetails?.id, () => setPageLoader(false)));
    dispatch(GetDeviceVersions(devices.toString(), () => setPageLoader(false)));
  }, []);

  const handleCancel = () => {
    setPageLoader(true);
    dispatch(
      CancelJobDetails(jobDetails?.id, () => {
        setPageLoader(false);
        setJobDetails({ id: "", state: false });
      })
    );
  };
  return (
    <div>
      <Row style={{ marginTop: "0.3rem" }}>
        <Button
          style={{ border: "none", background: "none" }}
          onClick={() => setJobDetails({ id: "", state: false })}
        >
          <ArrowLeftOutlined style={{ fontSize: "1.3rem" }} />
        </Button>
        <label style={{ fontSize: "1.3rem", textDecoration: "underline" }}>
          Job Details :
        </label>
      </Row>
      <Row
        style={{
          padding: "0.5rem 0rem 0rem 2rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div>
            <label style={{ fontWeight: "bold" }}>Job Id : </label>
            <label style={{ fontSize: "1rem" }}>{jobData?.jobId}</label>
          </div>
          <div>
            <label style={{ fontWeight: "bold" }}>Start Time : </label>
            <label style={{ fontSize: "1rem" }}>{jobData?.startTime}</label>
          </div>
          <div>
            <label style={{ fontWeight: "bold" }}>End Time : </label>
            <label style={{ fontSize: "1rem" }}>{jobData?.endTime}</label>
          </div>
          <div>
            <label style={{ fontWeight: "bold" }}>Status : </label>
            <label style={{ fontSize: "1rem" }}>{jobData?.status}</label>
          </div>
        </div>
        {jobData?.status === "scheduled" && (
          <div>
            <Button className="buttonStyles" onClick={() => handleCancel}>
              Cancel
            </Button>
          </div>
        )}
      </Row>
      <Divider />
      <Row>
      {deviceVersions?
          <Col span={24}>
            <Table columns={columns} dataSource={deviceVersions}></Table>
          </Col>:''
        }
        {/* {deviceVersions?.map((item, index) => (
          <Col span={24}>
            <Table columns={columns} dataSource={deviceVersions}></Table>
          </Col>
        ))} */}
      </Row>
      <PageDataLoader visible={pageLoader} />
    </div>
  );
};

export default SoftwareJobDetails;
