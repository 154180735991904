import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Modal, Select, Input } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import {
  GetManageSoftwareList,
  GetManageSoftwareUpdate,
  DeleteManageSoftware,
} from "pages/gateway-management/index";
import { connect, useSelector } from "react-redux";
import PageDataLoader from "partials/loader";

const ManageSoftwarePackages = ({
  GetManageSoftwareList,
  GetManageSoftwareUpdate,
  DeleteManageSoftware,
}) => {
  const [pageLoader, setPageLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [file, setFile] = useState();
  // const [updateType, setUpdateType] = useState("Major");
  // const [version, setVersion] = useState();
  const [description, setDescription] = useState();
  const tableSelect = selectedRows.length > 0;

  let manageSoftwareList = useSelector(
    (state) => state.gateway.manageSoftwareList
  );

  useEffect(() => {
    setPageLoader(true);
    GetManageSoftwareList({}, () => setPageLoader(false));
  }, []);

  const columns = [
    {
      title: "File Name",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "TImestamp",
      dataIndex: "timestamp",
      align: "center",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
  };

  const deleteSoftware = () => {
    setPageLoader(true);
    DeleteManageSoftware(
      {
        id: selectedRows?.[0]?.id,
      },
      () => {
        GetManageSoftwareList({}, () => setPageLoader(false));
      }
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);
    setPageLoader(true);
    GetManageSoftwareUpdate(formData, handleRefresh);
    setDescription();
    setFile();
    setSelectedRows([]);
  };

  const handleRefresh = () => {
    setPageLoader(true);
    GetManageSoftwareList({}, () => setPageLoader(false));
  };

  const handlefile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "white",
          padding: "15px",
        }}
      >
        <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
          Manage Software Packages
        </div>

        <Row>
          <Col
            xl={{ span: 2, offset: 16 }}
            lg={{ span: 1, offset: 17 }}
            md={{ span: 1, offset: 10 }}
            sm={{ span: 1, offset: 10 }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              title="Refresh"
              icon={<ReloadOutlined />}
              onClick={handleRefresh}
            ></Button>
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              onClick={showModal}
            >
              Upload
            </Button>
            <Modal
              title="Upload Software"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "0.5rem",
                  marginTop: "2rem",
                }}
              >
                {/* <label>Update Type</label>
                <Select
                  onChange={handleUpdatetype}
                  options={[
                    {
                      value: "Major",
                      label: "Major",
                    },
                    {
                      value: "Minor",
                      label: "Minor",
                    },
                    {
                      value: "Patch",
                      label: "Patch",
                    },
                  ]}
                ></Select>
                <label>Version</label>
                <Input
                  onChange={handleVersion}
                  placeholder="Enter Version "
                ></Input> */}
                <label>Description</label>
                <Input
                  onChange={handleDescription}
                  placeholder="Enter Description "
                ></Input>
                <label>File</label>
                <input
                  type="file"
                  accept=".zip,.tar.gz,.bin"
                  onChange={handlefile}
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "60%",
                  marginTop: "2rem",
                }}
              >
                <Button
                  style={{
                    marginRight: "1rem",
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                  onClick={handleOk}
                >
                  Submit
                </Button>
              </div>
            </Modal>
          </Col>
          <Col
            xl={{ span: 3 }}
            lg={{ span: 3 }}
            md={{ span: 6 }}
            sm={{ span: 6 }}
          >
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              onClick={deleteSoftware}
              disabled={!tableSelect}
            >
              Delete
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: "0.5rem" }}>
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey={(record) => record.filename}
              columns={columns}
              dataSource={manageSoftwareList}
            />
          </Col>
        </Row>
        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetManageSoftwareList: (obj, callback) =>
    dispatch(GetManageSoftwareList(obj, callback)),
  GetManageSoftwareUpdate: (obj, callback) =>
    dispatch(GetManageSoftwareUpdate(obj, callback)),
  DeleteManageSoftware: (obj, callback) =>
    dispatch(DeleteManageSoftware(obj, callback)),
});
export default connect(null, mapDispatchToProps)(ManageSoftwarePackages);
// export default ManageSoftwarePackages;
