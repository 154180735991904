import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import SideMenu from "./sideMenu";

const { Sider } = Layout;
const AuthLayout = () => {
  return (
    <Layout>
      <Layout style={{ height: "100vh" }}>
        <Sider>
          <SideMenu />
        </Sider>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
