import React from "react";
import { Tabs, Card, Button } from "antd";
import PageDataLoader from "partials/loader";
import ConnectivityStatus from "./connectivitystatus";
import Setting from "./setting";
import Network from "./network";
// import RobotController from "./robotcontroller";
// import Certificates from "./certificates";

const { TabPane } = Tabs;

const Configuration = () => {
  const pageLoader = false; // Set your pageLoader state here

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          backgroundColor: "#FFFFFF",
          padding: "1rem",
        }}
      >  <div style={{ fontSize: "1rem", marginBottom: "10px" }}>
      ENABLE / DISABLE MONITORING
    </div>
        <div style={{ padding: "1rem" }}>
          <Card title="Connectivity Status">
            <ConnectivityStatus />
          </Card>
          <Card title="Settings" style={{ margin: "1rem 0rem" }}>
            <Setting />
          </Card>
          <Card title="Network" style={{ margin: "1rem 0rem" }}>
            <Network />
          </Card>
          {/* <Card title="Leaf Devices" style={{ margin: "1rem 0rem" }}>
            <RobotController />
          </Card> */}
          {/* <Card title="Certificates" style={{ margin: "1rem 0rem" }}>
            <Certificates />
          </Card> */}
        </div>
      </div>
      <PageDataLoader visible={pageLoader} />
    </>
  );
};

export default Configuration;
