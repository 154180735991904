import axios from "axios";
import { validationtokenAction } from "./store";
import userBaseUrl from "services/userBaseUrl";

export const ValidateTokenFromNewUser = (object, callback) => {
  const newToken = object?.validationTokenFromLink?.id;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userBaseUrl}/users/validateToken`, {
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
        
          }
        });
    });
  };
};

export const SetPasswordForNewUser = (object, callback) => {

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${userBaseUrl}/users/setPassword`,
          {
            password: object?.password,
          },
          {
            headers: {
              Authorization: `Bearer ${object?.token}`,
            },
          }
        )
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              validationtokenAction.userSetPassword({
                setPassword: response?.data,
              })
            );
           
          }
        });
    });
  };
};
