import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

const userManagementSiteSlice = createSlice({
    name: "usermagementsite",
    initialState,
    reducers: {
      useDataList(state, action) {
        state.userList = [...action.payload.userList];
      },
      useDataDeleteList(state, action) {
        state.userDelete = [...action.payload.userDelete];
      },
      addcustomerDataList(state, action) {
        state.addCustomerList = [...action.payload.addCustomerList];
      },
      customersDataList(state, action) {
        state.customerList = [...action.payload.customerList];
      },
      customerDataDeleteList(state, action) {
        state.customerDelete = [...action.payload.customerDelete];
      },
      useRoleData(state, action) {
        state.userRole = [...action.payload.userRole];
      },
    },
  });
  
  export default userManagementSiteSlice.reducer;
  
  export const userManagementSiteActions = userManagementSiteSlice.actions;