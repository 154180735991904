import React, { useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

const MapContainer = ({ setLatitude, setLongitude, setSubModel }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAQ0-eJOoHhRcNji6BoPo6PapZ-tcNeRdE",
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: 48.1351,
    lng: 11.582,
  });

  const center = { lat: markerPosition.lat, lng: markerPosition.lng };

  const handleDrag = (e) => {
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());

    setMarkerPosition({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleMapClick = (e) => {
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());
  };

  return (
    <div>
      {isLoaded && (
        <div>
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              minWidth: "100%",
              height: "61vh",
              minHeight: "95%",
            }}
            onClick={handleMapClick}
            center={center}
            zoom={4}
            defaultCenter={markerPosition}
          >
            <Marker
              position={center}
              draggable={true}
              onDragEnd={handleDrag}
            ></Marker>
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default MapContainer;
