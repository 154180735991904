import {
  Row,
  Col,
  Input,
  Button,
  Table,
  Radio,
  Tag,
  Switch,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  GetDeviceList,
  AddNewDevice,
  DeleteDevices,
  EditDevices,
  UpdateDeviceStatus,
} from "pages/gateway-management/index";
import PageDataLoader from "partials/loader";
import AddGateway from "./addgateway";
import filterIcon from "assets/filterIcon.png";
import deleteIcon from "assets/deleteIcon.svg";
import greydeleteIcon from "assets/greydeleteIcon.svg";
import "../styles.css";

const Gateway = ({
  GetDeviceList,
  DeleteDevices,
  EditDevices,
  AddNewDevice,
  UpdateDeviceStatus,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [pageLoader, setPageLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [newDevice, setNewDevice] = useState([]);
 

  const deviceData = useSelector((state) => state.gateway.deviceData);

 

  const hasSelected = selectedRows.length > 0;

  const handleStatusToggle = (e, text, record) => {
    setPageLoader(true);
    let status;
    if (e === true) {
      status = "enabled";
    } else {
      status = "disabled";
    }
    UpdateDeviceStatus(
      {
        status: status,
        deviceId: record?.deviceId,
      },
      () => setPageLoader(false)
    );
  };

  const columns = [
    {
      title: "Device Name",
      dataIndex: "devicename",
      align: "center",
    },
    {
      title: "Device Id",
      dataIndex: "deviceId",
      align: "center",
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              localStorage.setItem("deviceId", record?.deviceId);
              navigate("/gateways/edit-gateway", { state: record?.deviceId });
            }}
          >
            {text}
          </Button>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "Hardware Id",
      dataIndex: "hardwareid",
      align: "center",
    },
    {
      title: "Tenant Id",
      dataIndex: "tenantid",
      align: "center",
    },
    {
      title: "Region",
      dataIndex: "region",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => {
        return (
          <Switch
            checkedChildren="enable"
            unCheckedChildren="disable"
            defaultChecked={text === "enabled" ? true : false}
            onChange={(e) => handleStatusToggle(e, text, record)}
          />
        );
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      align: "center",
    },
    {
      title: "Connectivity",
      dataIndex: "connectionState",
      align: "center",
      render: (text) => (
        <>
          {text === "Connected" ? (
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: "#32CD32",
              }}
            ></div>
          ) : (
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: "red",
              }}
            ></div>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    setPageLoader(true);
    GetDeviceList({}, () => setPageLoader(false));
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
    
      setSelectedRows(selectedRows);
    },
  };

  const handleDelete = () => {
    const reqObj = [];
    selectedRows?.forEach((item) => {
      reqObj.push(item?.deviceId);
    });
    setPageLoader(true);
    DeleteDevices(
      {
        deviceId: reqObj,
      },
      (res) => {
        notification.success({
          message: "Notification",
          description: res?.message,
          duration: [3],
        });
        GetDeviceList({}, () => setPageLoader(false));
      }
    );
    setSelectedRows([]);
  };

  const handleStatus = (e) => {
    const reqObj = [];
    selectedRows?.forEach((item) => {
      reqObj.push({
        deviceId: item?.deviceId,
        status: e.target.value,
      });
    });
    setPageLoader(true);
    EditDevices(reqObj, () => {
      GetDeviceList({}, () => setPageLoader(false));
    });
    setSelectedRows([]);
  };

  useEffect(() => {
    if (newDevice.length > 0) {
      setPageLoader(true);
      AddNewDevice(newDevice, (res) => {
       
        if (res !== undefined) {
          notification.success({
            message: "Notification",
            description: res?.message,
            duration: [3],
          });
        }

        GetDeviceList({}, () => setPageLoader(false));
      });
    }
  }, [newDevice]);

  // const data = [
  //   {
  //     deviceId: "string1",
  //     status: "enabled",
  //   },
  // ];

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "#FFFFFF",
          height: "91%",
        }}
      >
        <Row
          style={{
            padding: "15px",
          }}
        >
          <Col
            xl={{ span: 4 }}
            lg={{ span: 4 }}
            md={{ span: 7 }}
            sm={{ span: 7 }}
            style={{ marginRight: "0.5rem" }}
          >
            <Input
              placeholder="Search"
              style={{ backgroundColor: "#FCF6F1" }}
              prefix={<SearchOutlined />}
            />
          </Col>

          <Col
            xl={{ span: 4 }}
            lg={{ span: 4 }}
            md={{ span: 7 }}
            sm={{ span: 7 }}
          >
            <Radio.Group
              buttonStyle="solid"
              disabled={!hasSelected}
              onChange={handleStatus}
            >
              <Radio.Button value="enabled">Enabled</Radio.Button>
              <Radio.Button value="disabled">Disabled</Radio.Button>
            </Radio.Group>
          </Col>

          <Col
            xl={{ span: 1, offset: 11 }}
            lg={{ span: 1, offset: 11 }}
            md={{ span: 2 }}
            sm={{ span: 2 }}
          ></Col>

          <Col
            xl={{ span: 1 }}
            lg={{ span: 1 }}
            md={{ span: 2 }}
            sm={{ span: 2 }}
          >
            <Button
              style={{ background: "none", border: "none" }}
              onClick={handleDelete}
              disabled={!hasSelected}
            >
              {hasSelected ? (
                <img src={deleteIcon} alt="" style={{ height: "1.5rem" }} />
              ) : (
                <img src={greydeleteIcon} alt="" style={{ height: "1.5rem" }} />
              )}
            </Button>
          </Col>
          <Col
            xl={{ span: 2 }}
            lg={{ span: 2 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
          >
            <AddGateway setNewDevice={setNewDevice} />
          </Col>
        </Row>
        <Row style={{ padding: "0px 0.5rem 0.5rem 0.5rem" }}>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Table
              rowSelection={rowSelection}
              rowKey={(record) => record.deviceId}
              columns={columns}
              dataSource={deviceData}
            />
          </Col>
        </Row>
        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetDeviceList: (obj, callback) => dispatch(GetDeviceList(obj, callback)),
  AddNewDevice: (obj, callback) => dispatch(AddNewDevice(obj, callback)),
  DeleteDevices: (obj, callback) => dispatch(DeleteDevices(obj, callback)),
  EditDevices: (obj, callback) => dispatch(EditDevices(obj, callback)),
  UpdateDeviceStatus: (obj, callback) =>
    dispatch(UpdateDeviceStatus(obj, callback)),
});
export default connect(null, mapDispatchToProps)(Gateway);
// export default Gateway;
