import Api from "services/api";
import { gatewayActions } from "./store";
import { notification } from "antd";
import userBaseUrl from "services/userBaseUrl"
import axios from 'axios';
const accessToken = window.localStorage.getItem("accessToken")
export const GetDeviceList = (object, callback) => {
  return (dispatch) => {
   
    // const url = `/_deviceid=go-demo-1`;
    const url = `/device?tenantId=go-demo-1`;
    return new Promise((resolve, reject) => {
      Api.get(url)
      .send((response) => {
        if (callback) callback(response);
        if (response !== undefined) {
          dispatch(
            gatewayActions.DeviceList({
              deviceData: response,
            })
          );
        }
      });
    });
  };
};

export const AddNewDevice = (object, callback) => {
  let obj = object?.[0];
  return (dispatch) => {
    const url = `/device`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(obj)
        .send((response) => {
          if (callback) callback(response);
        
          if (response !== undefined) {
            dispatch(
              gatewayActions.AddNewDevice({
                addnewDevice: response,
              })
            );
          }
        });
    });
  };
};

export const GetDeviceDetails = (object, callback) => {
  const deviceId = localStorage.getItem("deviceId");
  return (dispatch) => {
    const url = `/device/${deviceId}`;
    return new Promise((resolve, reject) => {
      Api.get(url).send((response) => {
     

        if (callback) callback(response);
        if (response !== undefined) {
          dispatch(
            gatewayActions.DeviceDetails({
              deviceDetails: response,
            })
          );
        }
      });
    });
  };
};

export const UpdateDeviceStatus = (object, callback) => {
  const deviceId = object?.deviceId;
  delete object["deviceId"];
  return (dispatch) => {
    const url = `/device/status/${deviceId}`;
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.UpdateDeviceStatus({
                UpdateDeviceStatus: response,
              })
            );
            notification.open({
              message: "Notification",
              description: response,
              duration: [3],
            });
          }
        });
    });
  };
};

export const DeleteDevices = (object, callback) => {
  return (dispatch) => {
    const url = `${userBaseUrl}/device/deleteDevices`;
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`, // Make sure accessToken is defined
      },
      data: object, // Include the object as the request body
    };

    return new Promise((resolve, reject) => {
      axios.delete(url, config)
        .then((response) => {
          if (callback) callback(response.data);
          dispatch(
            gatewayActions.DeleteDevices({
              deleteDevices: response.data,
            })
          );
          resolve(response.data);
        })
        .catch((error) => {
          console.error('DeleteDevices API error:', error);
          reject(error);
        });
    });
  };
};

export const EditDevices = (object, callback) => {
  return (dispatch) => {
    const url = `/device/updateDevices`;
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.EditDevices({
                editDevices: response,
              })
            );
            notification.open({
              message: "Notification",
              description: response,
              duration: [3],
            });
          }
        });
    });
  };
};

export const GetFirmwareList = (object, callback) => {
  return (dispatch) => {
    const url = `/ota/firmware`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.FirmwareList({
                firmwareList: response,
              })
            );
          }
        });
    });
  };
};

export const GetFirmwareUpdate = (object, callback) => {
  return (dispatch) => {
    const url = `/ota/firmware`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.FirmwareUpdate({
                firmwareUpdate: response,
              })
            );
            notification.open({
              message: "Notification",
              description: response,
              duration: [3],
            });
          }
        });
    });
  };
};

export const GetSSHCredentials = (object, callback) => {
  const deviceId = localStorage.getItem("deviceId");
  return (dispatch) => {
    const url = `/command/ssh/${deviceId}`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.SSHCredentials({
                sshCredentials: response,
              })
            );
            if (response !== "device not connected") {
              notification.success({
                message: "Notification",
                description: "Click on Eye Icon for SSH Credentials",
                duration: [3],
              });
            } else if (response === "device not connected") {
              notification.open({
                message: "Notification",
                description: response,
                duration: [3],
              });
            }
          }
        });
    });
  };
};

export const RequestDeviceLogs = (object, callback) => {
  const deviceId = localStorage.getItem("deviceId");
  return (dispatch) => {
    const url = `/command/logs/${deviceId}`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.requestDeviceLogs({
                deviceLogs: response,
              })
            );
            if (response === "logs not found") {
              notification.open({
                message: "Notification",
                description: response,
                duration: [3],
              });
            }
          }
        });
    });
  };
};

export const DeleteFirmware = (object, callback) => {
  const fileId = object?.id;
  return (dispatch) => {
    const url = `/ota/firmware/${fileId}`;
    return new Promise((resolve, reject) => {
      Api.delete(url).send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          dispatch(
            gatewayActions.DeleteDevices({
              deleteDevices: response,
            })
          );
          notification.open({
            message: "Notification",
            description: response,
            duration: [3],
          });
        }
      });
    });
  };
};

export const GetManageSoftwareList = (object, callback) => {
  return (dispatch) => {
    const url = `/ota/software`;
    return new Promise((resolve, reject) => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.ManageSoftwareList({
                manageSoftwareList: response,
              })
            );
          }
        });
    });
  };
};

export const GetManageSoftwareUpdate = (object, callback) => {
  return (dispatch) => {
    const url = `/ota/software`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.ManageSoftwareUpdate({
                manageSoftwareUpdate: response,
              })
            );
            notification.open({
              message: "Notification",
              description: response,
              duration: [3],
            });
          }
        });
    });
  };
};
export const DeleteManageSoftware = (object, callback) => {
  const fileId = object?.id;
  return (dispatch) => {
    const url = `/ota/software/${fileId}`;
    return new Promise((resolve, reject) => {
      Api.delete(url).send((response) => {
        if (callback) {
          callback();
        }
        if (response !== undefined) {
          dispatch(
            gatewayActions.DeleteManageSoftwareDevice({
              deleteManageSoftwareDevice: response,
            })
          );
          notification.open({
            message: "Notification",
            description: response,
            duration: [3],
          });
        }
      });
    });
  };
};

export const SetScheduleFirmware = (object, callback) => {
  return () => {
    const url = `/ota/firmware/push`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback(response);
          }
        });
    });
  };
};

export const SetScheduleSoftware = (object, callback) => {
  return () => {
    const url = `/ota/software/push`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback(response);
          }
        });
    });
  };
};

export const GetSamplingTime = (object, callback) => {
  return () => {
    const url = `/command/directMethod/${object?.deviceId}`;
    delete object.deviceId;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback(response);
          }
        });
    });
  };
};

export const GetFirmwareUpdateList = (object, callback) => {
  return (dispatch) => {
    const url = "/ota/firmwareupdatelist";
    return new Promise(() => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.FirmwareUpdateList({
                firmwareUpdateList: response,
              })
            );
          }
        });
    });
  };
};

export const GetSoftwareUpdateList = (object, callback) => {
  return (dispatch) => {
    const url = "/ota/softwareupdatelist";
    return new Promise(() => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.SoftwareUpdateList({
                softwareUpdateList: response,
              })
            );
          }
        });
    });
  };
};

export const SetNetworkDevice = (object, callback) => {
  let params;
  if (object?.type === "wifi") {
    params = {
      properties: {
        desired: {
          networkdetails: {
            type: "wifi",
            payload: {
              ssid: object?.ssId,
              password: object?.password,
            },
          },
        },
      },
    };
  }
  if (object?.type === "GSM") {
    params = {
      properties: {
        desired: {
          networkdetails: {
            type: "gsm",
            payload: {
              country: object?.country,
              networkProvider: object?.networkProvider,
              apn: object?.apn,
              username: object?.username,
              password: object?.password,
              pin: object?.pin,
              dial: object?.dial,
            },
          },
        },
      },
    };
  }
  return (dispatch) => {
    const url = `/device/deviceTwin/${object?.deviceId}`;
    return new Promise(() => {
      Api.patch(url)
        .params(params)
        .send((response) => {
          if (callback) {
            callback(response);
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.NetworkUpdateList({
                networkUpdateList: response,
              })
            );
          }
        });
    });
  };
};

export const ConnectNetworkDevice = (object, callback) => {
  let params = {
    methodName: "updateNetworkDetails",
    payload: null,
    responseTimeoutInSeconds: 15,
  };
  return () => {
    const url = `/command/directMethod/${object}`;
    return new Promise(() => {
      Api.post(url)
        .params(params)
        .send((response) => {
          if (callback) {
            callback(response);
          }
        });
    });
  };
};

export const GetJobDetails = (object, callback) => {
  return (dispatch) => {
    const url = `/job/${object}`;
    return new Promise(() => {
      Api.get(url).send((response) => {
        if (callback) {
          callback(response);
        }
        dispatch(
          gatewayActions.FirmwareJobDetails({
            firmwareJobDetails: response,
          })
        );
      });
    });
  };
};

export const CancelJobDetails = (object, callback) => {
  return () => {
    const url = `/job/${object}`;
    return new Promise(() => {
      Api.put(url).send((response) => {
        if (callback) {
          callback(response);
        }
      });
    });
  };
};

export const GetDeviceVersions = (object, callback) => {
  return (dispatch) => {
    const url = `/device/deviceTwin?deviceList=${object}`;
    return new Promise(() => {
      Api.get(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback(response);
          }
          dispatch(
            gatewayActions.DeviceVersion({
              deviceVersions: response,
            })
          );
        });
    });
  };
};

export const UpdateDevice = (object, callback) => {
  const deviceId = object?.deviceId;
  delete object["deviceId"];
  return (dispatch) => {
    const url = `/device/status/${deviceId}`;
    return new Promise((resolve, reject) => {
      Api.patch(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.UpdateDevice({
                UpdateDevice: response,
              })
            );
            notification.open({
              message: "Notification",
              description: response,
              duration: [3],
            });
          }
        });
    });
  };
};

export const SetLoggingLevel = (object, callback) => {
  const deviceId = object?.deviceId;
  delete object["deviceId"];
  return (dispatch) => {
    const url = `/command/directMethod/${deviceId}`;
    return new Promise((resolve, reject) => {
      Api.post(url)
        .params(object)
        .send((response) => {
          if (callback) {
            callback();
          }
          if (response !== undefined) {
            dispatch(
              gatewayActions.setLogLevel({
                logLevel: response,
              })
            );
            if (response?.message) {
              notification.open({
                message: "Notification",
                description: response.message,
                duration: [3],
              });
            } else {
              notification.open({
                message: "Notification",
                description: response,
                duration: [3],
              });
            }
          }
        });
    });
  };
};
