import React from "react";
import Header from "partials/header";

const MappingSignal = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Header pagename={"Dashboard Builder"} />
      </div>
    </>
  );
};

export default MappingSignal;
