import React, { useState } from "react";
import {
  Input,
  Button,
  Modal,
  Form,
  Col,
  Row,
  Select,
  notification,
} from "antd";
import MapContainer from "./mapContainer";
const AddGateway = ({ setNewDevice }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubModel, setSubModel] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onReset = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const openGeolocation = () => {
    setLatitude(latitude);
    setLongitude(longitude);
    setSubModel(true);
  };

  const closeGeolocation = () => {
    setSubModel(false);
  };

  const onFinish = (values) => {
    const array = [];
    let enteredTags = values.tags;
    const array2 = enteredTags?.split(",");
    const newDevice = {
      deviceId: values.deviceId,
      deviceName: values.deviceName,
      description: values.description,
      hardwareId: values.hardwareId,
      modelno: values.modelno,
      tags: array2,
      tenantId: values.tenantId,
      productionLine: values.productionLine,
      region: values.region,
      geoLocation: {
        latitude: latitude.toString(),
        longitude: longitude.toString(),
      },
      url: "localhost",
    };
    array.push(newDevice);
    setNewDevice(array);
    if (latitude && longitude) {
      setIsModalOpen(false);
      setLatitude("");
      setLongitude("");
      form.resetFields();
    }
  };

  return (
    <>
      <Button
        style={{ backgroundColor: "#6B9E9A", color: "black" }}
        onClick={showModal}
      >
        Add Device
      </Button>
      <Modal
        title="Add a New Device"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Device Name"
                name="deviceName"
                rules={[
                  {
                    required: true,
                    message: "Please input Device Name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Device Id"
                name="deviceId"
                rules={[
                  {
                    required: true,
                    message: "Please input your Device Id",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Container Id"
                name="containerId"
                rules={[
                  {
                    required: true,
                    message: "Please input Container Id",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Model No "
                name="modelno"
                rules={[
                  {
                    required: true,
                    message: "Please input Model No",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            </Row>
          <Row gutter={[10, 10]}>

            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Hardware ID (Serial No)"
                name="hardwareId"
                rules={[
                  {
                    required: true,
                    message: "Please input hardwareId",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Tenant ID"
                name="tenantId"
                rules={[
                  {
                    required: true,
                    message: "Please input tenantId",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            </Row>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input  Description",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Tags (Seperate by Comma)"
                name="tags"
                rules={[
                  {
                    required: true,
                    message: "Please input Tags",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Production Line"
                name="productionLine"
                rules={[
                  {
                    required: true,
                    message: "Please ProductionLine ",
                  },
                ]}
              >
                <Select
                  placeholder="Select a ProductionLine"
                  options={[
                    {
                      value: "group1",
                      label: "Group 1",
                    },
                    {
                      value: "group2",
                      label: "Group 2",
                    },
                  ]}
                  allowClear
                ></Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Region"
                name="region"
                rules={[
                  {
                    required: true,
                    message: "Please region",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Region"
                  options={[
                    {
                      value: "India",
                      label: "India",
                    },
                    {
                      value: "USA",
                      label: "USA",
                    },
                  ]}
                  allowClear
                ></Select>
              </Form.Item>
              {/* <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Region"
                name="region"
                rules={[
                  {
                    required: true,
                    message: "Please input region ",
                  },
                ]}
              >
                <Input />
              </Form.Item> */}
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: "0px", marginTop: "0px" }}
                label="Geolocation"
                name="gelocation"
                rules={[
                  {
                    required: latitude && longitude ? false : true,
                    message: "Please input Geolocation",
                  },
                ]}
              >
                <Button
                  htmlType="button"
                  style={{ backgroundColor: "#6B9E9A", color: "black" }}
                  onClick={openGeolocation}
                >
                  Select Geolocation
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[10, 10]} style={{ marginTop: "2rem" }}>
            <Col span={12} offset={8}>
              <Form.Item>
                <Button
                  htmlType="button"
                  onClick={onReset}
                  style={{ backgroundColor: "#6B9E9A", color: "black" }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                    marginLeft: "1rem",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {isSubModel && (
        <Modal
          open={isSubModel}
          footer={false}
          onOk={openGeolocation}
          onCancel={closeGeolocation}
        >
          <MapContainer
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setSubModel={setSubModel}
          />
          <Row justify={"end"}>
            <Button
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
                marginTop: "1rem",
              }}
              onClick={() => {
                if (latitude && longitude) {
                  
                  closeGeolocation();
                } else {
                  notification.error({
                    message: "Its a default Latitude and Longitude",
                    description: `Please Select a Latitude and Longitude`,
                    duration: [3],
                  });
                }
              }}
            >
              Add Geolocation
            </Button>
          </Row>
        </Modal>
      )}
    </>
  );
};

export default AddGateway;
