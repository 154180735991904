import {
  Button,
  DatePicker,
  Form,
  notification,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import moment from "moment";
import isMoment from "moment-timezone";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetFirmwareList,
  GetManageSoftwareList,
  SetScheduleFirmware,
  SetScheduleSoftware,
} from "../gateway-management/index.js";

const ScheduleFirmwareModal = ({ called, setIsModalOpen, setParams }) => {
  const [timeSelected, setTimeSelected] = useState();
  const { Option } = Select;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const firmwareList = useSelector((state) => state.gateway.firmwareList);

  const deviceData = useSelector((state) => state.gateway.deviceData);
  const manageSoftwareList = useSelector(
    (state) => state.gateway.manageSoftwareList
  );

  useEffect(() => {
    if (called === "firmware") {
      dispatch(GetFirmwareList({}));
    }
    if (called === "software") {
      dispatch(GetManageSoftwareList({}));
    }
  }, []);
  const onFinish = (values) => {
    form.resetFields();
    setIsModalOpen(false);
    let params = {
      otaId: values?.fileName,
      deviceId: values?.deviceList,
      tags: values?.tags,
      responseTimeoutInSeconds: 15,
      maxExecutionTimeInSeconds: 180,
      startTime: moment.tz(timeSelected, isMoment.tz.guess()).utc().format(),
    };
    setParams(params);
  };
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="File Name"
        name="fileName"
        rules={[
          {
            required: true,
            message: "Please Select File!",
          },
        ]}
      >
       
        <Select>
          {called === "firmware"
            ? firmwareList?.map((file) => {
                return <Option value={file?.id}>{file?.filename}|{file?.version}|{file?.description}|{file?.timestamp}</Option>;
              })
            : manageSoftwareList?.map((file) => {
                 return <Option value={file?.id}>{file?.filename}|{file?.version}|{file?.description}|{file?.timestamp}</Option>;
              })}
        </Select>
      </Form.Item>
      <Form.Item
                label="Tags"
                name="tags"
                rules={[
                  {
                    required: false,
                    message: "Please input Tags",
                  },
                ]}
              >
                <Input />
      </Form.Item>
      <Form.Item
        label="Device List"
        name="deviceList"
        rules={[
          {
            required: true,
            message: "Please Select your Device!",
          },
        ]}
      >
        <Select mode="multiple" allowClear>
          {deviceData?.map((device) => {
            return <Option value={device?.deviceId}>{device?.deviceId}</Option>;
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Schedule Time"
        name="scheduleTime"
        rules={[
          {
            required: true,
            message: "Please Select Schedule Time!",
          },
        ]}
      >
        <DatePicker
          showTime
          style={{ width: "100%" }}
          onChange={(d, t) => setTimeSelected(t)}
        />
      </Form.Item>

      <Row justify="center" style={{ marginTop: "30px" }}>
        <Space>
          <Form.Item>
            <Button
              className="buttonStyles"
               onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button className="buttonStyles" htmlType="submit">
              Schedule
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export default ScheduleFirmwareModal;
