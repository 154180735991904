import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table, Modal, notification } from "antd";
import ScheduleFirmwareModal from "../firmwares/scheduleFirmwareModal";
import {
  GetSoftwareUpdateList,
  SetScheduleSoftware,
  GetDeviceList,
} from "../gateway-management/index";
import PageDataLoader from "partials/loader";
import { connect, useDispatch, useSelector } from "react-redux";
import SoftwareJobDetails from "./softwareJobDetails";

const ScheduleSoftwareUpdates = ({ GetDeviceList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [jobDetails, setJobDetails] = useState({ state: false, id: "" });
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setPageLoader(true);
    dispatch(
      GetSoftwareUpdateList({}, () => {
        GetDeviceList({}, () => setPageLoader(false));
      })
    );
  }, []);

  useEffect(() => {
    if (Object.keys(params).length) {
      setPageLoader(true);
      dispatch(
        SetScheduleSoftware(params, (res) => {
          dispatch(GetSoftwareUpdateList({}, () => setPageLoader(false)));
          notification.open({
            message: "Notification",
            description: res.message,
            duration: [3],
          });
        })
      );
    }
  }, [params]);

  const softwareUpdateList = useSelector(
    (state) => state.gateway.softwareUpdateList
  );
  const columns = [
    {
      title: "Job Id",
      dataIndex: "jobid",
      align: "center",
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={(e) => {
              setJobDetails({
                id: text,
                state: true,
                devices: record?.devicelist,
                filename: record?.filename,
              });
            }}
          >
            {text}
          </Button>
        );
      },
    },
    {
      title: "File Name",
      dataIndex: "filename",
      align: "center",
    },
    {
      title: "Device List",
      dataIndex: "devicelist",
      align: "center",
      render: (text, record) => {
        return <>{text.toString()}</>;
      },
    },
    {
      title: "Time Stamp",
      dataIndex: "timestamp",
      align: "center",
    },
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "white",
          padding: "15px",
        }}
      >
        <div style={{ fontSize: "1rem", marginBottom: "1rem" }}>
          Schedule Software Updates
        </div>

        {jobDetails.state ? (
          <SoftwareJobDetails
            setJobDetails={setJobDetails}
            jobDetails={jobDetails}
          />
        ) : (
          <>
            <Row>
              <Col
                xl={{ span: 3, offset: 21 }}
                lg={{ span: 3, offset: 21 }}
                md={{ span: 6, offset: 18 }}
                sm={{ span: 6, offset: 18 }}
              >
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                  onClick={() => setIsModalOpen(true)}
                >
                  Schedule
                </Button>
                <Modal
                  title="Schedule a Job"
                  open={isModalOpen}
                  onOk={() => setIsModalOpen(false)}
                  onCancel={() => setIsModalOpen(false)}
                  footer={[]}
                >
                  <ScheduleFirmwareModal
                    setIsModalOpen={setIsModalOpen}
                    called="software"
                    setParams={setParams}
                  />
                </Modal>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: "0.5rem" }}>
                <Table columns={columns} dataSource={softwareUpdateList} />
              </Col>
            </Row>
          </>
        )}
        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetDeviceList: (obj, callback) => dispatch(GetDeviceList(obj, callback)),
});
export default connect(null, mapDispatchToProps)(ScheduleSoftwareUpdates);

// export default ScheduleSoftwareUpdates;
