import React, { useState } from "react";
import { Tabs } from "antd";
import Header from "partials/header";
import ManageFirmware from "./managefirmware";
import ScheduleFirmware from "./schedulefirmware";

const { TabPane } = Tabs;

const Firmwares = () => {
  const [subpage, setSubpage] = useState("Manage Firmware");

  const onChange = (key) => {
    if (parseInt(key) === 1) {
      setSubpage("Manage Firmware");
    } else if (parseInt(key) === 2) {
      setSubpage("Schedule Firmware Updates");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Header pagename={"Firmwares"} subpage={subpage} />
      <div style={{ height: "90vh", overflowY: "auto" }}>
        <div style={{ padding: "15px 15px 0" }}>
          <Tabs tabPosition={"left"} defaultActiveKey="1" onChange={onChange}>
            <TabPane tab="Manage Firmware" key="1">
              <ManageFirmware />
            </TabPane>
            <TabPane tab="Schedule Fimware Updates" key="2">
              <ScheduleFirmware />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Firmwares;
