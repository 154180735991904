import React, { useEffect, useState } from "react";
import { Avatar, Col, Row, Popover, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import userBaseUrl from "services/userBaseUrl";

export const Header = ({ pagename, subpage }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const refreshToken = window.localStorage.getItem("refreshToken");
  const [sessionModalOpen, setSessionModalOpen] = useState(false);

  const decodeRefreshToken = (refreshToken) => {
    const tokenParts = refreshToken.split(".");
    const payloadObject = JSON.parse(atob(tokenParts?.[1]));
    const expirationTime = payloadObject.exp;
    return expirationTime; // Convert the expiration time to a Date object
  };
  const expTime = decodeRefreshToken(refreshToken);
  const ExpiretimeFunction = (expTime) => {
    return Number(expTime) == (parseInt(Date.now() / 1000) | 0);
  };
  useEffect(() => {
    setInterval(() => {
      let demo = ExpiretimeFunction(expTime - 120);
      let demo2 = ExpiretimeFunction(expTime);

      if (demo == true) {
        setSessionModalOpen(true);
      } else if (demo2 == true) {
        SessionEndModal();
      }
    }, 1000);
  }, []);

  const ConfirmeSessionModal = () => {
    axios
      .get(`${userBaseUrl}/users/refreshToken`, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      })
      .then((response) => {
        
        if (response?.status === 200) {
          window.localStorage.setItem(
            "accessToken",
            response?.data?.accessToken
          );
          window.localStorage.setItem(
            "refreshToken",
            response?.data?.refreshToken
          );
        }
      });
    setSessionModalOpen(false);
  };
  const SessionEndModal = () => {
    setSessionModalOpen(false);
    window.localStorage.clear();
    navigate("/");
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const content = (
    <div>
      <button
        style={{
          display: "flex",
          border: "none",
          background: "white",
          cursor: "pointer",
        }}
        onClick={handleLogout}
      >
        <b className="iconCol">Logout</b>
      </button>
    </div>
  );
  return (
    <>
      <Row
        style={{
          padding: "15px 15px 5px 15px",
          fontFamily: "poppins",
          borderBottom: "1px solid #6B9E9A",
        }}
      >
        <Col
          xl={{ span: 23 }}
          lg={{ span: 23 }}
          md={{ span: 22 }}
          xs={{ span: 22 }}
        >
          <label
            style={{
              fontSize: "2rem",
              borderRight: "1px solid Grey",
              paddingRight: "1rem",
            }}
          >
            Asset Management
          </label>
          <label
            style={{
              fontSize: "1.5rem",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            {pagename}
          </label>
          {subpage ? (
            <>
              <label
                style={{
                  fontSize: "1.5rem",
                  paddingLeft: "1rem",
                  borderLeft: "1px solid Grey",
                }}
              >
                {subpage}
              </label>
            </>
          ) : (
            <></>
          )}
        </Col>

        <Col
          xl={{ span: 1 }}
          lg={{ span: 1 }}
          md={{ span: 2 }}
          xs={{ span: 2 }}
          style={{
            alignContent: "right",
            textAlign: "right",
            alignItems: "right",
          }}
        >
          <div>
            <button
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                backgroundColor: "#FCF6F1",
                padding: "0.5rem 0.5rem 0.5rem 0.5rem",
                borderRadius: "0.5rem",
              }}
            >
              <Popover
                placement="bottomRight"
                content={content}
                trigger="click"
                open={visible}
                onOpenChange={handleVisibleChange}
              >
                <Avatar
                  size="small"
                  icon={<UserOutlined />}
                  style={{
                    color: "black",
                    backgroundColor: "#6b9e9a",
                  }}
                />
              </Popover>
            </button>
          </div>
        </Col>
      </Row>
      <Modal
        open={sessionModalOpen}
        onOk={ConfirmeSessionModal}
        onCancel={SessionEndModal}
        okText="EXTEND "
        cancelText="Cancel"
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          SESSION GOING TO EXPIRED IN 2 MIN.
        </h3>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          DO YOU WANT TO EXTEND THE SESSION?
        </h3>
      </Modal>
    </>
  );
};

export default Header;
