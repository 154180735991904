import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import loginReducer from "pages/login/store";
import gatewayReducer from "pages/gateway-management/store";
import customerSiteReducer from "pages/customer-site/store"
import usermagementsiteReducer from "pages/user-management/store"
import valudatetokenReducer from "pages/validate-token/store"
import logger from "redux-logger";

const store = configureStore({
  reducer: {
    login: loginReducer,
    gateway: gatewayReducer,
    customerSite : customerSiteReducer,
    usermagementsite :usermagementsiteReducer,
    validationtokensite:valudatetokenReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export default store;
