import React, { useState, useEffect } from "react";
import { Tabs, Row, Button, notification, Space } from "antd";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { connect, useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ManageHardware from "./managehardware";
import Configuration from "./configuration/configuration";
import Debug from "./debug";
import Header from "partials/header";
import PageDataLoader from "partials/loader";
import baseUrl from "services/baseUrl";
import userBaseUrl from "services/userBaseUrl"
import {
  GetDeviceDetails,
  GetDeviceList,
} from "pages/gateway-management/index";
import { gatewayActions } from "pages/gateway-management/store";
import RobowareLicences from "./robowareLicences";
import axios from "axios";
import { saveAs } from 'file-saver';
const { TabPane } = Tabs;
const accessToken = window.localStorage.getItem("accessToken")

const EditGateway = ({ GetDeviceDetails, GetDeviceList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [pageLoader, setPageLoader] = useState(false);
  const [subpage, setSubpage] = useState("Gateways");
  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);


  const handleClick = () => {
    dispatch(
      gatewayActions.DeviceDetails({
        deviceDetails: [],
      })
    );
    window.history.back()
    // navigate("/home");
  };

  const downloadDeviceDetails = () => {
    axios({
      url: `${userBaseUrl}/device/${deviceDetails?.deviceId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(function (response) {
        if (response !== undefined) {
          let res = response.data;
  
          // Save the blob as a file
          saveAs(res, `${deviceDetails?.deviceId}.json`);
  
          setTimeout(() => {
            notification.success({
              message: "Notification",
              description: "Downloaded successfully",
              duration: [3],
            });
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setPageLoader(true);
    GetDeviceDetails({}, () => {
      GetDeviceList({}, () => setPageLoader(false));
    });
  }, []);

  const onChange = (key) => {
    if (parseInt(key) === 1) {
      setSubpage("Gateways");
    } else if (parseInt(key) === 2) {
      setSubpage("Manage Hardware");
    } else if (parseInt(key) === 3) {
      setSubpage("Debug");
    } else if (parseInt(key) === 4) {
      setSubpage("Leaf Device Licences");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Header pagename={"Asset Management"} subpage={subpage} />
      <div style={{ height: "90vh", overflowY: "auto" }}>
        <div
          style={{
            padding: "2px",
            border: "1px solid #6B9E9A",
            borderRadius: "1rem",
            margin: "1rem",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Row style={{ marginTop: "0.3rem" }}>
              <Button
                style={{ border: "none", background: "none" }}
                onClick={handleClick}
              >
                <ArrowLeftOutlined style={{ fontSize: "1.3rem" }} />
              </Button>
              <label
                style={{ fontSize: "1.3rem", textDecoration: "underline" }}
              >
                Device Info :
              </label>
            </Row>
            <Row style={{ marginTop: "0.3rem" }}>
              <Space>
                <div style={{ fontWeight: "bold" }}>Device details :</div>
                <Button
                  title="download"
                  style={{
                    border: "none",
                    backgroundColor: "#6B9E9A",
                    color: "black",
                    marginRight: "0.5rem",
                  }}
                  onClick={downloadDeviceDetails}
                >
                  <DownloadOutlined style={{ fontSize: "1.3rem" }} />
                </Button>
              </Space>
            </Row>
          </div>
          <Row style={{ padding: "0.5rem 0rem 0rem 2rem" }}>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "1rem",
                marginBottom: "2rem",
              }}
            >
              <div>
                <label>Device Name : </label>
                <label style={{ fontSize: "1rem" }}>
                  {deviceDetails?.devicename}
                </label>
              </div>

              <div>
                <label>Description : </label>
                <label style={{ fontSize: "1rem" }}>
                  {deviceDetails?.description}
                </label>
              </div>
              <div>
                <label>Hardware Id : </label>
                <label style={{ fontSize: "1rem" }}>
                  {deviceDetails?.hardwareid}
                </label>
              </div>

              <div>
                <label>Tenant Id : </label>
                <label style={{ fontSize: "1rem" }}>
                  {deviceDetails?.tenantid}
                </label>
              </div>
              <div>
                <label> Tags : </label>
                <label style={{ fontSize: "1rem" }}>
                  {deviceDetails?.tags}
                </label>
              </div>
            </div>
          </Row>
        </div>

        <div style={{ padding: "15px 15px 0" }}>
          <Tabs
            tabPosition={"left"}
            defaultActiveKey="1"
            onChange={onChange}
            style={{ height: "60vh" }}
          >
            <TabPane tab="Configuration" key="1">
              <Configuration />
            </TabPane>

            <TabPane tab="Manage Hardware" key="2">
              <ManageHardware />
            </TabPane>
            <TabPane tab="Debug" key="3">
              <Debug />
            </TabPane>
            {/* <TabPane tab="Leaf Device Licences" key="4">
              <RobowareLicences />
            </TabPane> */}
          </Tabs>
        </div>

        <PageDataLoader visible={pageLoader} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetDeviceDetails: (obj, callback) =>
    dispatch(GetDeviceDetails(obj, callback)),
  GetDeviceList: (obj, callback) => dispatch(GetDeviceList(obj, callback)),
});
export default connect(null, mapDispatchToProps)(EditGateway);
// export default EditGateway;
