import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    updateLoginDetails(state, action) {
      state.loginData = { ...action.payload.loginData };
    },
    updateLogged: (state, action) => {
      state.logged = action.payload.logged;
    },
    updateLoginRoleDetails: (state, action) => {
      state.loginroleData = action.payload.loginroleData;
    },
  },
});

export default loginSlice.reducer;

export const loginActions = loginSlice.actions;
