import Api from "services/api";
import { customerSiteActions } from "./store";
import {notification} from "antd";
import hierarchyBaseUrl from "services/hierarchyBaseUrl";
import axios from "axios";

 
export const GetCustomerList = (object, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${hierarchyBaseUrl}/onedesendants/${object?.initialPathName}`
        )
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              customerSiteActions.customerList({
                customerData: response?.data?.desendants,
              })
            );
          }
        });
    });
  };
};

export const DeleteCustomer = (object, callback) => {
  
  return (dispatch) => {
    
    return new Promise((resolve, reject) => {
      axios
      .delete(`${hierarchyBaseUrl}/delete_hierarchy/${object}`
      )
      .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              customerSiteActions.Deletecust({
                deleteData: response.data,
              })
            );
            
          }
        });
    });
  };
};

export const AddUser = (object, callback) => {
 
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${hierarchyBaseUrl}/add_hierarchy`,object
        )
        .then(function (response) {
          
         
          if (callback) callback(response);
          if (response !== undefined) {
            notification.success({ description: response?.data?.message });
            dispatch(
              customerSiteActions.AddUserData({
                addUserData: response,
              })
            );
          }
        });
    });
  };
};

export const EditUser = (object, callback) => {
 
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${hierarchyBaseUrl}/update_hierarchy/${object?.editData?.guid}`,object?.editHierarchyData 
        )
        .then(function (response) {
          
         
          if (callback) callback(response);
          if (response !== undefined) {
            notification.success({ description: response?.data?.message });
            dispatch(
              customerSiteActions.EditUserData({
                editUserData: response,
              })
            );
          }
        });
    });
  };
};

