import React, { useEffect, useState } from "react";
import Header from "partials/header";
import {
  Button,
  Col,
  Row,
  Table,
  Modal,
  Select,
  Input,
  Form,
  notification,
  Tag,
  Spin,
  InputNumber,
} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { connect, useSelector } from "react-redux";
import {
  AddUserInLeast,
  DeleteUser,
  GetUserList,
  SendRestPassword,
  AddCustomerInLeast,
  GetCustomerList,
  DeleteCustomer,
  GetUserRole,
} from "pages/user-management/index";
const UserManagement = ({
  GetUserList,
  AddUserInLeast,
  DeleteUser,
  SendRestPassword,
  AddCustomerInLeast,
  GetCustomerList,
  DeleteCustomer,
  GetUserRole,
}) => {
  const [form] = Form.useForm();
  const accessToken = window.localStorage.getItem("accessToken");
  
  const DataUser = useSelector((state) => state?.usermagementsite?.userList);
  const CustomerDataUser = useSelector(
    (state) => state?.usermagementsite?.customerList
  );
  const DataRoleUser = useSelector(
    (state) => state?.login?.loginroleData?.status
  );

  const [subpage, setSubpage] = useState("");
  const [isRegirtrationModal, setIsRegirtrationModal] = useState(false);
  const [isAddCustomernModal, setIsAddCustomernModal] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [listApiLoader, setListApiLoader] = useState(false);
  const [allUser, setAllUser] = useState();
  const [allCustomer, setAllCustomer] = useState();
  const [userEdit, setUserEdit] = useState();
  const [customerDelete, setCustomerDelete] = useState();
  const [userDelete, setUserDelete] = useState();
  const [adminCode, setAdminCode] = useState();

  const Usercolumns = [
    {
      title: "SN",
      dataIndex: "index",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "User Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Mobile No.",
      dataIndex: "phone",
      align: "center",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "role",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (text, record, index) => (
        <div style={{ textAlign: "center" }}>
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserEdit(record?.email);
            }}
          >
            Reset Password
          </Tag>
        </div>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      render: (text, record, index) => (
        <div style={{ textAlign: "center" }}>
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => {
              setUserDelete(record?.email);
            }}
          >
            <DeleteOutlined />
          </Tag>
        </div>
      ),
    },
  ];

  const Customercolumns = [
    {
      title: "SN",
      dataIndex: "index",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      align: "center",
    },
    {
      title: "Mobile No.",
      dataIndex: "customerPhone",
      align: "center",
    },
    {
      title: "Email Address",
      dataIndex: ["customerAdmin", "email"],
      key: "email",
      align: "center",
    },
    {
      title: "Role",
      dataIndex: "productFeature",
      align: "center",
    },
    {
      title: "Address",
      dataIndex: "customerAddress",
      align: "center",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      render: (text, record, index) => (
        <div style={{ textAlign: "center" }}>
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => {
              setCustomerDelete(record?._id);
            }}
          >
            <DeleteOutlined />
          </Tag>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setPageLoader(true);
    GetUserRole({accessToken}, (res) => {
    
      setAdminCode(res?.status);
      setPageLoader(false);
    });
    // setAdminCode(DataRoleUser);
  }, []);
  useEffect(() => {
    if (DataUser) {
      setAllUser(DataUser);
    }
    if (CustomerDataUser) {
      setAllCustomer(CustomerDataUser);
    }
  }, [DataUser, CustomerDataUser]);

  const showUserFormModal = () => {
    setIsRegirtrationModal(true);
  };
  const handleOk = () => {
    setIsRegirtrationModal(false);
  };

  const onFinish = (values) => {
    const paramsValue = {
      name: values?.userName,
      email: values?.userEmail,
      phone: values?.userMobileNo,
      role: values?.userRole,
    };
    // Add user in list
    let addUserParams = [paramsValue,accessToken]
    AddUserInLeast(addUserParams, (res) => {
      if (res) {
        setListApiLoader(!listApiLoader);
        notification.success({
          description: "Successfully sent link over Email",
        });
      }
    });
    form.resetFields();
  };
  const showCustomerFormModal = () => {
    setIsAddCustomernModal(true);
  };
  const handleCustomerOk = () => {
    setIsAddCustomernModal(false);
  };
  const onCustomerFinish = (values) => {
    const paramsCustomerValue = {
      name: values?.userName,
      email: values?.userEmail,
      phone: values?.userMobileNo,
      role: values?.userRole,
      customerName: values?.customerName,
      customerAdmin: {
        name: values?.customerName,
        email: values?.CustomerEmail,
      },
      platformSettings: {
        dashboardLink: values?.companyUrl,
      },
      productFeature: values?.productFeature,
      customerAddress: values?.customerAddress,
      customerPhone: values?.CustomerMobileNo,
    };
    // Add customer in list
    let addParams = [paramsCustomerValue,accessToken]
    AddCustomerInLeast(addParams, (res) => {
      if (res) {
        setListApiLoader(!listApiLoader);
        notification.success({
          description: "Successfully sent link over Email",
        });
      }
    });
    form.resetFields();
  };
  // get user list fromthis api
 
  useEffect(() => {
    setPageLoader(true);
    GetUserList({accessToken}, () => setPageLoader(false));
  }, [listApiLoader]);

  // delete the user from this api
  useEffect(() => {
    if (userDelete) {
      let UserDeleteDaa = [userDelete,accessToken]
      setPageLoader(true);
      DeleteUser({ UserDeleteDaa }, (res) => {
        setListApiLoader(!listApiLoader);
        if (res.status === 200) {
          setPageLoader(false);
          notification.success({
            description: "User deleted successfully",
          });
        } else if (res.status === 404) {
          notification.error({
            description: "Invalid Request",
          });
        }
      });
    }
  }, [userDelete]);
  // sent reset link over email by htis api
  useEffect(() => {
    if (userEdit) {
      let UserEditDaa = [userEdit,accessToken]
      setPageLoader(true);
      SendRestPassword({ UserEditDaa }, (res) => {
        if (res) {
          setPageLoader(false);
          notification.success({
            description: "Successfully sent link over Email",
          });
        }
      });
    }
  }, [userEdit]);
  // get customer list from this api
  useEffect(() => {
    setPageLoader(true);
    GetCustomerList({accessToken}, () => setPageLoader(false));
  }, [listApiLoader]);
  // delete customer
  useEffect(() => {
    if (customerDelete) {
      let customerDeleteDaa = [customerDelete,accessToken]
      setPageLoader(true);
      DeleteCustomer({ customerDeleteDaa }, (res) => {
        setListApiLoader(!listApiLoader);
        if (res.status === 200) {
          setPageLoader(false);
          notification.success({
            description: "Customer deleted successfully",
          });
        } else if (res.status === 404) {
          notification.error({
            description: "Invalid Request",
          });
        }
      });
    }
  }, [customerDelete]);

  return (
    <div style={{ width: "100%" }}>
      <Header pagename={"User Management"} subpage={subpage} />
      <div style={{ height: "90vh", overflowY: "auto" }}>
        <div style={{ display: "flex", justifyContent: "right" }}>
          {adminCode === 200 ?<Button
            type="primary"
            style={{
              backgroundColor: "#6B9E9A",
              color: "black",
              fontSize: "20px",
              height: "45px",
              margin: "1rem",
            }}
            onClick={showCustomerFormModal}
          >
            Add Customer
          </Button>:<Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
                fontSize: "20px",
                height: "45px",
                margin: "1rem",
              }}
              onClick={showUserFormModal}
            >
              Add User
            </Button>}
          
          
          <Modal
            title="Register User"
            open={isRegirtrationModal}
            onOk={handleOk}
            onCancel={() => setIsRegirtrationModal(false)}
            footer={null}
          >
            {/* <div
              style={{
                display: "grid",
                // gridTemplateColumns: "2fr 7fr",
                gap: "1rem",
                marginTop: "2rem",
              }}
            > */}
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true, userRole: "Admin" }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="User Name"
                name="userName"
                rules={[
                  {
                    required: true,
                    message: "Please input User Name !",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Mobile No"
                name="userMobileNo"
                rules={[
                  {
                    required: true,
                    message: "Please input User Mobile No !",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="User Email"
                name="userEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input User Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="User Role"
                name="userRole"
                rules={[
                  {
                    required: true,
                    message: "Please input User Role!",
                  },
                ]}
              >
                <Select
                  defaultValue="Admin"
                  style={{
                    width: 470,
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: "Admin",
                      label: "Admin",
                    },
                    {
                      value: "Technician",
                      label: "Technician",
                    },
                    {
                      value: "User",
                      label: "User",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleOk}
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                >
                  Submit
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsRegirtrationModal(false)}
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                    marginLeft: "20px",
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title="Add Customer"
            open={isAddCustomernModal}
            onOk={handleCustomerOk}
            onCancel={() => setIsAddCustomernModal(false)}
            footer={null}
          >
            {/* <div
              style={{
                display: "grid",
                // gridTemplateColumns: "2fr 7fr",
                gap: "1rem",
                marginTop: "2rem",
              }}
            > */}
            <Form
              name="basic"
              form={form}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true, productFeature: "base" }}
              onFinish={onCustomerFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Customer Name"
                name="customerName"
                rules={[
                  {
                    required: true,
                    message: "Please input Customer Name !",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Customer Mobile No"
                name="CustomerMobileNo"
                rules={[
                  {
                    required: true,
                    message: "Please input Customer Mobile No !",
                  },
                ]}
              >
                <InputNumber style={{ width: "29.5rem" }} />
              </Form.Item>
              <Form.Item
                label="Customer Email"
                name="CustomerEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input Customer Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Product Feature"
                name="productFeature"
                rules={[
                  {
                    required: true,
                    message: "Please  Select product Feature!",
                  },
                ]}
              >
                <Select
                  defaultValue="base"
                  style={{
                    width: 470,
                  }}
                  // onChange={handleChange}
                  options={[
                    {
                      value: "base",
                      label: "Base",
                    },
                    {
                      value: "diagnostic",
                      label: "Diagnostic",
                    },
                    {
                      value: "predictive",
                      label: "Predictive",
                    },
                    {
                      value: "prescriptive",
                      label: "Prescriptive",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Company Url"
                name="companyUrl"
                rules={[
                  {
                    required: true,
                    message: "Please input Company Url!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Customer Address"
                name="customerAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input Customer Address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleCustomerOk}
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                  }}
                >
                  Submit
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsAddCustomernModal(false)}
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                    marginLeft: "20px",
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
{adminCode === 200 ?<Row style={{ padding: "0px 0.5rem 0.5rem 0.5rem" }}>
          <Col
            xl={{ span: 24 }}
            lg={{ span: 24 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
          >
            <Spin
              spinning={pageLoader}
              tip="Loading...."
              size="large"
              style={{ fontSize: "20px" }}
            >
              <Table
                // rowSelection={rowSelection}
                // rowKey={(record) => record.deviceId}
                columns={Customercolumns}
                dataSource={allCustomer}
              />
            </Spin>
          </Col>
        </Row>: <Row style={{ padding: "0px 0.5rem 0.5rem 0.5rem" }}>
            <Col
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
            >
              <Spin
                spinning={pageLoader}
                tip="Loading...."
                size="large"
                style={{ fontSize: "20px" }}
              >
                <Table
                  // rowSelection={rowSelection}
                  // rowKey={(record) => record.deviceId}
                  columns={Usercolumns}
                  dataSource={allUser}
                />
              </Spin>
            </Col>
          </Row>}
        

       
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetUserList: (obj, callback) => dispatch(GetUserList(obj, callback)),
  AddUserInLeast: (obj, callback) => dispatch(AddUserInLeast(obj, callback)),
  DeleteUser: (obj, callback) => dispatch(DeleteUser(obj, callback)),
  SendRestPassword: (obj, callback) =>
    dispatch(SendRestPassword(obj, callback)),
  AddCustomerInLeast: (obj, callback) =>
    dispatch(AddCustomerInLeast(obj, callback)),
  GetCustomerList: (obj, callback) => dispatch(GetCustomerList(obj, callback)),
  DeleteCustomer: (obj, callback) => dispatch(DeleteCustomer(obj, callback)),
  GetUserRole: (obj, callback) => dispatch(GetUserRole(obj, callback)),
});
export default connect(null, mapDispatchToProps)(UserManagement);
