import React, { useState } from "react";
import { Button, Popover, Card, Row, Radio, Space } from "antd";
import { connect, useSelector } from "react-redux";
import {
  GetSSHCredentials,
  RequestDeviceLogs,
  SetLoggingLevel,
} from "pages/gateway-management/index";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import PageDataLoader from "partials/loader";

const Debug = ({ GetSSHCredentials, RequestDeviceLogs, SetLoggingLevel }) => {
  const [pageLoader, setPageLoader] = useState(false);
  const [sshDataToggle, setSshDataToggle] = useState(false);
  const [deviceEnable, setDeviceEnable] = useState(false);
  const [logLevel, setLogLevel] = useState();
  const sshCredentials = useSelector((state) => state.gateway.sshCredentials);
  const deviceLogs = useSelector((state) => state.gateway.deviceLogs);
  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);

  const handleGetCredentials = () => {
    setSshDataToggle(true);
    setPageLoader(true);
    GetSSHCredentials({}, () => setPageLoader(false));
  };

  const handleDeviceLogs = () => {
    setDeviceEnable(true);
    setPageLoader(true);
    RequestDeviceLogs({}, () => setPageLoader(false));
  };

  const handleLoggingLevel = (e) => {
    setPageLoader(true);
    SetLoggingLevel(
      {
        methodName: "configurationUpdate",
        payload: {
          setloglevel: logLevel,
        },
        responseTimeoutInSeconds: 0,
        deviceId: deviceDetails?.deviceId,
      },
      () => setPageLoader(false)
    );
  };

  const handleRadio = (e) => {
    setLogLevel(e.target.value);
  };

  const content = (
    <>
      <div style={{ marginTop: "2rem" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "0.5rem",
            marginBottom: "2rem",
          }}
        >
          <div>
            <label>Username : </label>
            <label style={{ fontSize: "1rem" }}>
              {sshCredentials?.username}
            </label>
          </div>
          <div>
            <label>Key : </label>
            <label style={{ fontSize: "1rem" }}>
              {sshCredentials?.password}
            </label>
          </div>
          <div>
            <label>IP Address : </label>
            <label style={{ fontSize: "1rem" }}>
              {sshCredentials?.ipAddress}
            </label>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "white",
          padding: "15px",
        }}
      >
        <div style={{ fontSize: "1rem" }}>Debug</div>
        <div style={{ marginTop: "2rem" }}>
          <Card title="Enable/Disable Logging" style={{ margin: "1rem 0rem" }}>
            <div style={{ fontSize: "1rem", marginBottom: "0.5rem" }}>
              Logging Level :
            </div>
            <Radio.Group defaultValue="info" onChange={handleRadio}>
              <Space direction="vertical">
                <Radio value="error">Error</Radio>
                <Radio value="warning">Warning</Radio>
                <Radio value="info">Info</Radio>
                <Radio value="verbose">Verbose</Radio>
              </Space>
            </Radio.Group>
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
                display: "block",
                margin: "1rem 1rem 1rem 0",
              }}
              onClick={handleLoggingLevel}
            >
              Set Logging Level
            </Button>
          </Card>

          <Card title="Device Logs">
            <Row>
              <Button
                type="primary"
                style={{
                  backgroundColor: "#6B9E9A",
                  color: "black",
                  display: "block",
                  margin: "0 1rem 10px 0",
                }}
                onClick={handleDeviceLogs}
              >
                Request Device Logs
              </Button>
              <div style={{ marginLeft: "1rem" }}>
                Device Logs :
                <a
                  href={deviceLogs}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#6B9E9A",
                      color: "black",
                      marginLeft: "1rem",
                    }}
                    disabled={deviceEnable ? false : true}
                    icon={<DownloadOutlined />}
                  ></Button>
                </a>
              </div>
            </Row>
          </Card>
          <Card title="SSH Credentails" style={{ margin: "1rem 0rem" }}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
                margin: "0 1rem 10px 0",
              }}
              onClick={handleGetCredentials}
            >
              Get SSH Credentails
            </Button>

            <span>
              <Popover
                content={content}
                title="SSH Credentails"
                trigger="click"
              >
                SSH Credentails:
                <Button
                  style={{ border: "none", marginLeft: "0.5rem" }}
                  disabled={sshDataToggle ? false : true}
                >
                  <EyeOutlined />
                </Button>{" "}
              </Popover>
            </span>
          </Card>
        </div>

        <PageDataLoader visible={pageLoader} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetSSHCredentials: (obj, callback) =>
    dispatch(GetSSHCredentials(obj, callback)),
  RequestDeviceLogs: (obj, callback) =>
    dispatch(RequestDeviceLogs(obj, callback)),
  SetLoggingLevel: (obj, callback) => dispatch(SetLoggingLevel(obj, callback)),
});
export default connect(null, mapDispatchToProps)(Debug);
// export default Debug;
