import React from "react";
import { Input, Button, Form, notification, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConnectNetworkDevice, SetNetworkDevice } from "../../index";

const DeviceGSMForm = ({ setPageLoader }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);

  const onFinish = (values) => {
    if (deviceDetails?.connectionState === "Connected") {
      setPageLoader(true);
      values.deviceId = deviceDetails?.deviceId;
      values.type = "GSM";

      dispatch(
        SetNetworkDevice(values, (res) => {
          form.resetFields();
          setPageLoader(false);
          notification.success({
            message: "Notification",
            description: "Saved Successfully",
          });
        })
      );
    } else {
      notification.open({ description: "Device is not Conected" });
    }
  };
  const handleConnect = () => {
    if (deviceDetails?.connectionState === "Connected") {
      setPageLoader(true);
      let data = form.getFieldsValue();
      data.deviceId = deviceDetails?.deviceId;
      data.type = "GSM";

      dispatch(
        SetNetworkDevice(data, (res) => {
          dispatch(
            ConnectNetworkDevice(deviceDetails?.deviceId, (res) => {
              form.resetFields();
              setPageLoader(false);
              if (res?.status === 200) {
                notification.success({
                  message: "Notification",
                  description: "Connected Successfully",
                });
              } else {
                notification.error({
                  message: "Notification",
                  description: "Connection Error",
                });
              }
            })
          );
        })
      );
    } else {
      notification.open({ description: "Device is not Conected" });
    }
  };
  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: "Please input your Country!",
              },
            ]}
          >
            <Input placeholder="Please Enter Country" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Network Provider"
            name="networkProvider"
            rules={[
              {
                required: true,
                message: "Please input your Network Provider!",
              },
            ]}
          >
            <Input placeholder="Please Enter Network Provider" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="APN"
            name="apn"
            rules={[
              {
                required: true,
                message: "Please input your APN !",
              },
            ]}
          >
            <Input placeholder="Please Enter APN " />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input placeholder="Please Enter Username" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password placeholder="Please Enter Password" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            label="PIN"
            name="pin"
            rules={[
              {
                required: true,
                message: "Please input your PIN!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Dial"
            name="dial"
            rules={[
              {
                required: true,
                message: "Please input your Dial!",
              },
            ]}
          >
            <Input placeholder="Please Enter PIN " />
          </Form.Item>
        </Col>
      </Row>

      <div style={{ marginTop: "2rem", float: "right", display: "flex" }}>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="buttonStyles">
            Save
          </Button>
          <Button
            type="primary"
            className="buttonStyles"
            htmlType="button"
            onClick={() => form.resetFields()}
          >
            Reset
          </Button>
          <Button
            type="primary"
            className="buttonStyles"
            htmlType="button"
            // onClick={handleConnect}
          >
            Connect
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default DeviceGSMForm;
