import userBaseUrl from "services/userBaseUrl";
import { userManagementSiteActions } from "./store";
import axios from "axios";
import Api from "services/api";
import { notification } from "antd";


const accessToken = window.localStorage.getItem("accessToken");




export const GetUserList = (object, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userBaseUrl}/users`, {
          headers: {
            Authorization: `Bearer ${object?.accessToken}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.useDataList({
                userList: response?.data,
              })
            );
          }
        });
    });
  };
};
export const AddUserInLeast = (object, callback) => {
  let paramUser = object?.[0]
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${userBaseUrl}/users`, paramUser, {
          headers: {
            Authorization: `Bearer ${object?.[1]}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.useDataList({
                userList: response?.data,
              })
            );
          }
        });
    });
  };
};
export const DeleteUser = (object, callback) => {
  let deleteUserParam = object?.UserDeleteDaa
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${userBaseUrl}/users/user/${deleteUserParam?.[0]}`,{
          headers: {
            Authorization: `Bearer ${deleteUserParam?.[1]}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.useDataDeleteList({
                userDelete: response?.data,
              })
            );
          }
        })
        .catch(function (error) {
          if (callback) {
            callback(error.response);
          }
        });
    });
  };
};
export const SendRestPassword = (object, callback) => {
  let editUserParam = object?.UserEditDaa
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${userBaseUrl}/password/resetPassword`,
          {
            eMailId: editUserParam?.[0],
          },
          {
            headers: {
              Authorization: `Bearer ${editUserParam?.[1]}`,
            },
          }
        )
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.useDataList({
                userList: response?.data,
              })
            );
          }
        });
    });
  };
};

export const AddCustomerInLeast = (object, callback) => {
let param = object?.[0]
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${userBaseUrl}/customers`, param, {
          headers: {
            Authorization: `Bearer ${object?.[1]}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.addcustomerDataList({
                addCustomerList: response?.data,
              })
            );
          }
        });
    });
  };
};
// export const AddCustomerInLeast = (object, callback) => {
//   return (dispatch) => {
//     const url = `/customers`;
//     return new Promise((resolve, reject) => {
//       Api.post(url)
//         .params(object)
//         .send((response, error) => {
//           if (callback) {
//             callback(response, error);
//           }if (error) {
//             reject(error); 
//           } else if (response !== undefined) {
//             dispatch(
//               userManagementSiteActions.addcustomerDataList({
//                 addCustomerList: response?.data,
//               })
//             );
//             notification.open({
//               message: "Notification",
//               description: response,
//               duration: [3],
//             });
//             resolve(response);
//           }
//         });
//     });
//   };
// };

export const GetCustomerList = (object, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userBaseUrl}/customers`, 
        {
          headers: {
            Authorization: `Bearer ${object?.accessToken}`,
          },
        }
        )
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.customersDataList({
                customerList: response?.data,
              })
            );
          }
        });
    });
  };
};

// export const GetCustomerList = (object, callback) => {
//   return (dispatch) => {
//     const url = `/customers`;
//     return new Promise((resolve, reject) => {
//       Api.get(url)
//       .send((response) => {
//         if (callback) callback(response);
//         if (response !== undefined) {
//           dispatch(
//             userManagementSiteActions.customersDataList({
//               customerList: response,
//             })
//           );
//         }
//       });
//     });
//   };
// };
export const DeleteCustomer = (object, callback) => {
  let deleteParam = object?.customerDeleteDaa
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${userBaseUrl}/customers/${deleteParam?.[0]}`,{
          headers: {
            Authorization: `Bearer ${deleteParam?.[1]}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.customerDataDeleteList({
                customerDelete: response?.data,
              })
            );
          }
        })
        .catch(function (error) {
          if (callback) {
            callback(error.response);
          }
        });
    });
  };
};

// export const DeleteCustomer = (object, callback) => {
//   return (dispatch) => {
//     const url = `/customers/${object?.customerDelete}`;
//     return new Promise((resolve, reject) => {
//       Api.delete(url)
//         .then((response) => {
//           if (callback) callback(response, null);
//           dispatch(
//             userManagementSiteActions.customerDataDeleteList({
//               customerDelete: response?.data,
//             })
//           );
//           resolve(response);
//         })
//         .catch((error) => {
//           if (callback) callback(null, error.response);
//         });
//     });
//   };
// };







// export const GetUserRole = (object, callback) => {
//   return (dispatch) => {
//     const url = `/users/admin`;
//     return new Promise((resolve, reject) => {
//       Api.get(url).send((response, error) => { 
//         if (callback) callback(response, error);
//         if (error) {
//           reject(error);
//         } else if (response !== undefined) {
//           dispatch(
//             userManagementSiteActions.useRoleData({
//               userRole: response?.data?.[0],
//             })
//           );
//           resolve(response); 
//         }
//       });
//     });
//   };
// };

export const GetUserRole = (object, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userBaseUrl}/users/admin`, {
          headers: {
            Authorization: `Bearer ${object?.accessToken}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              userManagementSiteActions.useRoleData({
                userRole: response?.data,
              })
            );
          }
        }).catch(function (error) {
          if (callback) {
            callback(error.response);
          }
        });
    });
  };
};