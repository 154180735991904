import Api from "services/api";
import { loginActions } from "./store";
import axios from "axios";
import {notification} from "antd";
import userBaseUrl from "services/userBaseUrl";
const accessToken = window.localStorage.getItem("accessToken");

export const GetLoginDetails = (object, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${userBaseUrl}/users/authenticate`, object)
        .then(function (response) {
          if (callback) {
            callback(response);
          }
          if (response !== undefined) {
            window.localStorage.setItem("accessToken",response?.data?.accessToken);
            window.localStorage.setItem("refreshToken",response?.data?.refreshToken);
            Api.header(response?.data?.accessToken);
            dispatch(
              loginActions.updateLoginDetails({
                loginData: response,
              })
            );
            dispatch(
              loginActions.updateLogged({
                logged: true,
              })
            );
            // localStorage.setItem("loginData", response?.email);
          }
        })
        .catch(function (error) {
          if (callback) {
            callback(error.response);
          }
        });;
    });
  };
};
export const GetUserRole = (object, callback) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userBaseUrl}/users/admin`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(function (response) {
          if (callback) callback(response);
          if (response !== undefined) {
            dispatch(
              loginActions.updateLoginRoleDetails({
                loginroleData: response,
              })
            );
          }
        }).catch(function (error) {
          if (callback) {
            callback(error.response);
          }
        });
    });
  };
};
