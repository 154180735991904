import React from "react";
import { useSelector } from "react-redux";

const ConnectivityStatus = () => {
  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ marginRight: "1rem" }}>Connectivity Status :</div>
        {deviceDetails?.connectionState === "Connected" ? (
          <>
            <div
              style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: "#32CD32",
              }}
            ></div>
          </>
        ) : (
          <>
            <div
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                display: "inline-block",
                backgroundColor: "red",
              }}
            ></div>
          </>
        )}
      </div>
    </>
  );
};

export default ConnectivityStatus;
