import React from "react";
import Header from "partials/header";
import Gateway from "./gateway/gateway";
const GatewayManagement = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <Header pagename={"Gateway Management"} />
        <Gateway />
      </div>
    </>
  );
};

export default GatewayManagement;
