import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "pages/login/login";
import AuthLayout from "./partials/layout";
import Dashboard from "pages/dashboard/dashboard";
import GatewayManagement from "pages/gateway-management/gatewaymanagement";
// import CustomerSite from "pages/customer-site/customersite";
import MappingSignal from "pages/mapping-signal/mappingsignal";
import EditGateway from "pages/gateway-management/edit-gateway/editgateway";
import Firmwares from "pages/firmwares/firmware";
import Softwares from "pages/softwares/software";
import CustomersSite from "pages/customer-site/updated-customer-site/CustomersSite";
import Api from "services/api";
import UserManagement from "pages/user-management/usermanagement";
import ValidateToken from "pages/validate-token/ValidateToken";

function App() {
  const accessToken = window.localStorage.getItem("accessToken")
  
  if (accessToken) { Api.header(accessToken); }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="activateAccount/:id" element={<ValidateToken/>} />
        <Route path="resetPassword/:id" element={<ValidateToken/>} />
        <Route element={<AuthLayout />}>
          <Route path="home" element={<Dashboard />} />
          <Route path="gateways">
            <Route index={true} element={<GatewayManagement />} />
            <Route path="edit-gateway" element={<EditGateway />} />
          </Route>
          <Route path="firmwares" element={<Firmwares />} />
          <Route path="software-packages" element={<Softwares />} />
          <Route path="customers" element={<CustomersSite />} />
          <Route path="robot-dashboard" element={<MappingSignal />} />
          <Route path="user-management" element={<UserManagement/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
