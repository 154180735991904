import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

const validationtokenAndResetPasswordSlice = createSlice({
    name: "validationtokensite",
    initialState,
    reducers: {
      userSetPassword(state, action) {
        state.setPassword = [...action.payload.setPassword];
      },
    },
  });
  export default validationtokenAndResetPasswordSlice.reducer;
  
  export const validationtokenAction = validationtokenAndResetPasswordSlice.actions;