import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
import "./loaderStyles.css";
function PageDataLoader({ visible = false }) {
  const collapsed = localStorage.getItem("collapsed");
  return (
    <div>
      {visible && (
        <div
          style={
            !collapsed
              ? {
                  height: "100%",
                  width: "calc(100hw - 79px)",
                  position: "absolute",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  marginLeft: "79px",
                }
              : {
                  height: "100vh",
                  width: "calc(100hw - 240px)",
                  position: "absolute",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  marginLeft: "240px",
                }
          }
        >
          <div
            className="r-c-c"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#6B9E9A",
              width: 50,
              height: 50,
              borderRadius: 25,
            }}
          >
            <LoadingOutlined style={{ fontSize: 24, color: "white" }} />
          </div>
        </div>
      )}
    </div>
  );
}
export default PageDataLoader;
