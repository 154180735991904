import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const gatewaySlice = createSlice({
  name: "gateway",
  initialState,
  reducers: {
    DeviceList(state, action) {
      state.deviceData = [...action.payload.deviceData];
    },
    AddNewDevice(state, action) {
      state.addnewDevice = { ...action.payload.addnewDevice };
    },
    DeviceDetails(state, action) {
      state.deviceDetails = { ...action.payload.deviceDetails };
    },
    EditDevices(state, action) {
      state.editDevices = { ...action.payload.editDevices };
    },
    DeleteDevices(state, action) {
      state.deleteDevices = { ...action.payload.deleteDevices };
    },
    FirmwareList(state, action) {
      state.firmwareList = [...action.payload.firmwareList];
    },
    FirmwareUpdate(state, action) {
      state.firmwareUpdate = { ...action.payload.firmwareUpdate };
    },
    SSHCredentials(state, action) {
      state.sshCredentials = { ...action.payload.sshCredentials };
    },
    requestDeviceLogs(state, action) {
      state.deviceLogs = action.payload.deviceLogs;
    },
    UpdateDeviceStatus(state, action) {
      state.UpdateDeviceStatus = { ...action.payload.UpdateDeviceStatus };
    },
    AddDeviceResponse(state, action) {
      state.addDevice = { ...action.payload.addDevice };
    },
    ManageSoftwareList(state, action) {
      state.manageSoftwareList = [...action.payload.manageSoftwareList];
    },
    ManageSoftwareUpdate(state, action) {
      state.manageSoftwareUpdate = { ...action.payload.manageSoftwareUpdate };
    },
    DeleteManageSoftwareDevice(state, action) {
      state.deleteManageSoftwareDevice = {
        ...action.payload.deleteManageSoftwareDevice,
      };
    },
    FirmwareUpdateList(state, action) {
      state.firmwareUpdateList = [...action.payload.firmwareUpdateList];
    },
    SoftwareUpdateList(state, action) {
      state.softwareUpdateList = action.payload.softwareUpdateList;
    },
    NetworkUpdateList(state, action) {
      state.networkUpdateList = action.payload.networkUpdateList;
    },
    FirmwareJobDetails(state, action) {
      state.firmwareJobDetails = action.payload.firmwareJobDetails;
    },
    DeviceVersion(state, action) {
      state.deviceVersions = [...action.payload.deviceVersions];
    },
    UpdateDevice(state, action) {
      state.UpdateDevice = { ...action.payload.UpdateDevice };
    },
    setLogLevel(state, action) {
      state.logLevel = { ...action.payload.logLevel };
    },
  },
});

export default gatewaySlice.reducer;

export const gatewayActions = gatewaySlice.actions;
