import { createSlice } from "@reduxjs/toolkit";
const initialState = {};

const customerSiteSlice = createSlice({
    name: "customerSite",
    initialState,
    reducers: {
        customerList(state, action) {
            state.customerData = [...action.payload.customerData];
          },
          
    },
  });
  
  export default customerSiteSlice.reducer;
  
  export const customerSiteActions = customerSiteSlice.actions;