import React, { useState } from "react";
import { Input, Button, Divider, Form, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConnectNetworkDevice, SetNetworkDevice } from "../../index";
import PageDataLoader from "partials/loader";
import DeviceGSMForm from "./deviceGSMForm";

const Network = () => {
  const [pageLoader, setPageLoader] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);
  const onFinish = (values) => {
    setPageLoader(true);
    values.deviceId = deviceDetails?.deviceId;
    values.type = "wifi";
    dispatch(
      SetNetworkDevice(values, (res) => {
        form.resetFields();
        setPageLoader(false);
        notification.success({
          message: "Notification",
          description: "Saved Successfully",
        });
      })
    );
  };
  const handleConnect = () => {
    setPageLoader(true);
    let data = form.getFieldsValue();
    data.deviceId = deviceDetails?.deviceId;
    dispatch(
      SetNetworkDevice(data, (res) => {
        dispatch(
          ConnectNetworkDevice(deviceDetails?.deviceId, (res) => {
            form.resetFields();
            setPageLoader(false);
            if (res?.status === 200) {
              notification.success({
                message: "Notification",
                description: "Connected Successfully",
              });
            } else {
              notification.error({
                message: "Notification",
                description: "Connection Error",
              });
            }
          })
        );
      })
    );
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          padding: "10px",
          overflowY: "auto",
          overflowX: "hidden",
          height: "57vh",
        }}
      >
        <div>
          <div style={{ fontSize: "1.2rem" }}>Device WIFI Configuration</div>
          <Form
            form={form}
            name="basic"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              ssId: 'EBDT',
              password: 'ebdservic',
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="SSID"
              name="ssId"
              rules={[
                {
                  required: true,
                  message: "Please input your SSID!",
                },
              ]}
            >
              <Input placeholder="Please Enter SSID" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="Please Enter Password" />
            </Form.Item>
            <div style={{ marginTop: "2rem", display: "flex", float: "right" }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="buttonStyles"
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  className="buttonStyles"
                  htmlType="button"
                  onClick={() => form.resetFields()}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  className="buttonStyles"
                  htmlType="button"
                  onClick={handleConnect}
                >
                  Connect
                </Button>
              </Form.Item>
            </div>
          </Form>
          <Divider />
        </div>
        <div style={{ marginTop: "2rem" }}>
          <div style={{ marginBottom: "1rem", fontSize: "1.3rem" }}>
            Device GSM/LTE Configuration
          </div>
          <DeviceGSMForm setPageLoader={setPageLoader} />
        </div>
      </div>
      <PageDataLoader visible={pageLoader} />
    </>
  );
};

export default Network;
