import React, { useState } from "react";
import { Button, notification, Row, Col, Input, Card } from "antd";
import PageDataLoader from "partials/loader";
import { useDispatch, useSelector } from "react-redux";
import { GetSoftwareUpdateList } from "../index";

const ManageHardware = () => {
  const [pageLoader, setPageLoader] = useState(false);
  const [hardwareId, setHardwareId] = useState();
  const dispatch = useDispatch();

  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);

  const handleHardwareId = (e) => {
    setHardwareId(e.target.value);
  };

  const handleUpdate = () => {
    setPageLoader(true);
    dispatch(
      GetSoftwareUpdateList(
        {
          deviceId: deviceDetails?.deviceId,
          hardwareId: hardwareId,
        },
        () => setPageLoader(false)
      )
    );
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          overflowY: "auto",
          overflowX: "auto",
          backgroundColor: "white",
          padding: "15px",
        }}
      >
        <div style={{ fontSize: "1rem", marginBottom: "10px" }}>
          ManageHardware
        </div>
        <Card title="BackUp Configuration">
          <Button
            style={{
              backgroundColor: "#6B9E9A",
              color: "black",
              display: "block",
              marginBottom: "0.5rem",
            }}
            onClick={() => {
              setPageLoader(true);
              setTimeout(() => {
                setPageLoader(false);
                notification.success({ description: "Backup Successfull" });
              }, 3000);
            }}
          >
            Backup
          </Button>
        </Card>
        <Card title="Restore Configuration" style={{ margin: "1rem 0rem" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#6B9E9A",
              color: "black",
              display: "block",
              marginBottom: "0.5rem",
            }}
            onClick={() => {
              setPageLoader(true);
              setTimeout(() => {
                setPageLoader(false);
                notification.success({ description: "Restore Successfull" });
              }, 3000);
            }}
          >
            Restore
          </Button>
        </Card>
        <Card title="Replace Hardware" style={{ margin: "1rem 0rem" }}>
          <Row>
            <Col span={10} style={{ marginLeft: "2rem", marginBottom: "1rem" }}>
              <div style={{ display: "flex" }}>
                <label> Old Hardware ID : {deviceDetails?.hardwareid}</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12} style={{ marginLeft: "2rem" }}>
              <div style={{ display: "flex" }}>
                <label> New Hardware ID </label>
                <Input
                  style={{ marginLeft: "1rem" }}
                  placeholder="Please Enter Hardware Id"
                  onChange={handleHardwareId}
                ></Input>
                <Button
                  style={{
                    backgroundColor: "#6B9E9A",
                    color: "black",
                    display: "block",
                    marginLeft: "0.5rem",
                  }}
                  onClick={handleUpdate}
                >
                  Replace
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <PageDataLoader visible={pageLoader} />
    </>
  );
};

export default ManageHardware;
