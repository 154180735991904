import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Card,
  Radio,
  Space,
} from "antd";
import { connect, useSelector } from "react-redux";
import { GetSamplingTime } from "../../index";
import PageDataLoader from "partials/loader";

const Setting = ({ GetSamplingTime }) => {
  const [form] = Form.useForm();

  const deviceDetails = useSelector((state) => state.gateway.deviceDetails);
  const [pageLoader, setPageLoader] = useState(false);
  const [samplingtimeValue, setSamplingtimeValue] = useState(0);

  const handleSamplingtime = (e) => {
    if (e.target.value === " ") {
    } else {
      setSamplingtimeValue(parseInt(e.target.value));
    }
  };

  const anamolytype = (values) => {
    let obj = {
      methodName: "configurationUpdate",
      payload: {
        anamolytype: values.anamolytype,
      },
      responseTimeoutInSeconds: 0,
      deviceId: deviceDetails?.deviceId,
    };

    setPageLoader(true);
    GetSamplingTime(obj, (response) => {
      setPageLoader(false);
      notification.success({
        message: "Notification",
        description: response?.payload?.data,
        duration: [3],
      });
      form.resetFields();
    });
  };

  const handleSetSamplingtime = () => {
    let obj = {
      methodName: "configurationUpdate",
      payload: {
        samplingtime: samplingtimeValue,
      },
      responseTimeoutInSeconds: 0,
      deviceId: deviceDetails?.deviceId,
    };

    setPageLoader(true);
    GetSamplingTime(obj, (response) => {
      setPageLoader(false);
      notification.success({
        message: "Notification",
        description: response?.payload?.data,
        duration: [3],
      });
    });
  };
  return (
    <div>
      <Card title="Configuration ">
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={anamolytype}
          autoComplete="off"
        >
          {/* <Row gutter={[24, 24]}>
            <Form.Item name="anamolytype" label="Anamoly Type ">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="sensor_malfunction">sensor_malfunction</Radio>
                  <Radio value="calibration_drift">calibration_drift</Radio>
                  <Radio value="mechanical_anomaly">mechanical_anomaly</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Row> */}
          {/* <Row gutter={[24, 24]}> */}
            {/* <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#6B9E9A",
                  color: "black",
                }}
              >
                Set Anamoly Type
              </Button>
            </Form.Item> */}
          {/* </Row> */}
        </Form>
        <Row gutter={[24, 24]}>
          <Col span={5}>
            <div style={{ fontSize: "1rem" }}>Sampling Time :</div>
          </Col>
          <Col span={6}>
            <Input
              size="middle"
              placeholder="Please enter sampling time"
              onChange={handleSamplingtime}
            />
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              style={{
                backgroundColor: "#6B9E9A",
                color: "black",
              }}
              onClick={handleSetSamplingtime}
            >
              Set Sampling Time
            </Button>
          </Col>
        </Row>
      </Card>

      <PageDataLoader visible={pageLoader} />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  GetSamplingTime: (obj, callback) => dispatch(GetSamplingTime(obj, callback)),
});
export default connect(null, mapDispatchToProps)(Setting);

// export default Setting;
