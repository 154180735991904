import React,{ useState,useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  Card,
  notification,
  Spin
} from "antd";
import { useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { GetLoginDetails,GetUserRole } from "./index";
import backgroundImage from "assets/bg.jpg";
import PageDataLoader from "partials/loader";



const { Title } = Typography;

const Login = ({ GetLoginDetails,GetUserRole }) => {
  const [pageLoader, setPageLoader] = useState(false);
  const navigate = useNavigate();
  const loginInfo = useSelector((state) => state.login);
  
  // useEffect(() => {
  //   GetUserRole({}, () => {});
  // }, []);
  const handleForm = (values) => {
    setPageLoader(true);
    const loginDetails = {
      username: values?.email,
      password: values?.password,
    };
    GetLoginDetails(loginDetails,(res)=>{
      if (res && res.status === 200) {
        setPageLoader(false);
        navigate("/home");
        notification.success({ description: "Successfully logged-in" });
      } if (res && res.status === 404){
        setPageLoader(false);
        navigate("/");
        notification.error({ description: "Incorrect Username & Password" });
      }
    })
    
  };
  return (
    <>
    <Spin tip="Loging in..." spinning={pageLoader} delay={500} size="large" style={{marginTop:"30vh", fontSize:"30px"}}>
      <div
        style={{
          overflowY: "hidden",
          height: "100vh",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <Row
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Col
            style={{
              marginTop: "15%",
            }}
          >
            <Card
              bordered={false}
              style={{
                justifyContent: "center",
                width: "50vh",
                border: "2px solid black",
              }}
            >
              <div>
                <Title style={{ marginBottom: "30px" }}>Login</Title>
                <Form
                  name="basic"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleForm}
                  autoComplete="off"
                >
                  <Form.Item
                    label="E-mail ID"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required.",
                      },
                      {
                        type: "email",
                        message: "please enter a valid email address.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter E-mail ID" />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                      {
                        type: "pattern",
                        pattern: new RegExp(
                          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$"
                        ),
                        message: "please enter a valid password",
                      },
                    ]}
                  >
                    <Input.Password placeholder="Enter password" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      </Spin>
      {/* <PageDataLoader visible={pageLoader} /> */}
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  GetLoginDetails: (obj,callback) => dispatch(GetLoginDetails(obj, callback)),
  GetUserRole: (obj,callback) => dispatch(GetUserRole(obj, callback)),
  
});
export default connect(null, mapDispatchToProps)(Login);
// export default Login;
