import { Button, Form, Input, Row, Select, Space } from "antd";
import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetFirmwareList, GetFirmwareUpdate } from "../gateway-management/index";

const UploadFirmwareModal = ({
  setPageLoader,
  setIsModalOpen,
  handleRefresh,
}) => {
  const [file, setFile] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const firmwareList = useSelector((state) => state.gateway.firmwareList);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(GetFirmwareList());
  }, [dispatch]);

  const onFinish = (values) => {
    const matchingFiles = firmwareList.filter(
      (item) =>
        item.filename === file.name && item.version === values.version
    );

    if (matchingFiles.length > 0) {
      setErrorMessage("A file with the same filename and version already exists");
      return; // Stop execution if file with the same filename and version exists
    } else {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("updateType", values.updateType);
      formData.append("version", values.version);
      formData.append("description", values.description);
      form.resetFields();

      setPageLoader(true);
      setIsModalOpen(false);
      values.file = file;
      dispatch(GetFirmwareUpdate(formData, handleRefresh));
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Update Type"
        name="updateType"
        rules={[
          {
            required: true,
            message: "Please Select Update Type!",
          },
        ]}
      >
        <Select
          options={[
            {
              value: "Major",
              label: "Major",
            },
            {
              value: "Minor",
              label: "Minor",
            },
            {
              value: "Patch",
              label: "Patch",
            },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Version"
        name="version"
        rules={[
          {
            required: true,
            message: "Please Input Version!",
          },
        ]}
      >
        <Input placeholder="Enter Version" />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Please Input Description!",
          },
        ]}
      >
        <Input placeholder="Enter Description" />
      </Form.Item>

      <Form.Item
        label="File"
        name="file"
        rules={[
          {
            required: true,
            message: "Please Choose File!",
          },
        ]}
      >
        <Input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          accept=".zip,.tar.gz,.bin"
        />
      </Form.Item>
       {/* Render error message if exists */}
       {errorMessage && (
        <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
          <span style={{ color: "red" }}>{errorMessage}</span>
        </Form.Item>
      )}
      <Row justify="center" style={{ marginTop: "30px" }}>
        <Space>
          <Form.Item>
            <Button
              style={{ backgroundColor: "#6B9E9A", color: "black" }}
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              style={{ backgroundColor: "#6B9E9A", color: "black" }}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Row>
    </Form>
  );
};

export default UploadFirmwareModal;